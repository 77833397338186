const PostType = {
    POST: 'post',
    REELS: 'reels',
    SHORTS: 'shorts',
    STORY: 'story',
    VIDEO: 'video',
    DIRECT_POST: 'DIRECT_POST',
    UPLOAD_TO_INBOX: 'UPLOAD_TO_INBOX',
    STANDARD: 'STANDARD',
    OFFER: 'OFFER',
    EVENT: 'EVENT'
}

const MediaType = {
    VIDEO: 'video',
    IMAGE: 'image',
    DOCUMENT: 'document'
}
const ServiceName = {
    FACEBOOK: "FACEBOOK",
    INSTAGRAM: "INSTAGRAM",
    TWITTER: "TWITTER",
    YOUTUBE: "YOUTUBE",
    LINKEDIN: "LINKEDIN",
    TIKTOK: "TIKTOK",
    GBUSINESS: "GBUSINESS",
    PINTEREST: "PINTEREST"
}
const DisplayName = {
    FACEBOOK: "FACEBOOK",
    INSTAGRAM: "INSTAGRAM",
    TWITTER: "TWITTER",
    YOUTUBE: "YOUTUBE",
    LINKEDIN: "LINKEDIN",
    TIKTOK: "TIKTOK",
    GBUSINESS: "GOOGLE MY BUSINESS"
}
const RecentPostServiceName = {
    FACEBOOK: "facebook_service",
    INSTAGRAM: "instagram_service",
    TWITTER: "twitter_service",
    YOUTUBE: "youtube_service",
    LINKEDIN: "linkedIn_service",
    TIKTOK: "tiktok_service",
    GBUSINESS: "gbusiness_service",
    PINTEREST: "pinterest_service"
}

const DashboardProfileServiceName = {
    FACEBOOK: "dashboard_facebook_profile_stats",
    INSTAGRAM: "dashboard_instagram_profile_stats",
    TWITTER: "dashboard_twitter_profile_stats",
    YOUTUBE: "dashboard_youtube_profile_stats",
    LINKEDIN: "dashboard_linkedin_profile_stats",
    TIKTOK: "dashboard_tiktok_profile_stats",
    GBUSINESS: "dashboard_gbusiness_profile_stats",
    PINTEREST: "dashboard_pinterest_profile_stats"
}
const ProfileType = {
    PAGE: "PAGE",
    GROUP: "GROUP",
    PROFILE: "PROFILE"
}
const SupportedFileFormat = [
    "video/mp4",
    "image/jpeg",
    "image/png"
]
const TikTokPrivacyLevel = {
    PUBLIC_TO_EVERYONE: 'PUBLIC_TO_EVERYONE',
    MUTUAL_FOLLOW_FRIENDS: 'MUTUAL_FOLLOW_FRIENDS',
    FOLLOWER_OF_CREATOR: 'FOLLOWER_OF_CREATOR',
    SELF_ONLY: 'SELF_ONLY'
}
const YoutubePrivacyLevel = {
    PUBLIC: 'public',
    PRIVATE: 'private',
    UNLISTED: 'unlisted'
}
const TikTokOptions = {
    DISABLE_DUET: 'disable_duet',
    DISABLE_COMMENT: 'disable_comment',
    DISABLE_SWITCH: 'disable_switch',
    BRAND: 'your_brand',
    BRAND_CONTENT: 'brand_content',
    DISCLOSURE_CONTENT: 'disclosure_content'
}
const ServiceConfigurations = {
    FACEBOOK: {
        REELS: {
            MINSECONDS: 3, // 3 Seconds
            MAXSECONDS: 90, // 90 Seconds
            MAXWIDTH:1920,
            MAXHEIGHT:1920,
            MINWIDTH:540,
            MINHEIGHT:960,
            DESCRIPTION_MAX: 5000,
            MAXASPECTRATIO: 9/16,
            ASPECTRATIO: "9:16"
        },
        POST: {
            MAXSECONDS: 14400, // 4 Hours
            DESCRIPTION_MAX: 5000,
            ASPECTRATIO: "9:16"
        },
        STORY: {
            MINSECONDS: 3, // 3 Seconds
            MAXSECONDS: 60, // 60 Seconds
            ASPECTRATIO: "9:16"
        }
    },
    INSTAGRAM: {
        REELS: {
            MAXSECONDS: 900, // 90 Seconds
            MINSECONDS: 3, // 3 Seconds
            MAXWIDTH:1920,
            MAXHEIGHT:1920,
            DESCRIPTION_MAX: 2200,
            ASPECTRATIO: "9:16"
        },
        POST: {
            MAXSECONDS: 43200, // 12 Hours
            VIDEOLENGTH: 60,
            DESCRIPTION_MAX: 2200,
            MINWIDTH: 320,
            MAXWIDTH:1440,
        },
        STORY: {
            MINSECONDS: 3, // 3 Seconds
            MAXSECONDS: 60, // 60 Seconds
            MAXWIDTH:1920,
            MAXHEIGHT:1920,
            ASPECTRATIO: "9:16"
        }
    },
    YOUTUBE: {
        COMMON: {
            TITLE_MAX: 100,
            DESCRIPTION_MAX: 5000
        },
        SHORTS: {
            MAXSECONDS: 60, // 60 Seconds
            ASPECTRATIO: "9:16"
        },
        POST: {
            MAXSECONDS: 43200, // 12 Hours
        }
    },
    TWITTER: {
        POST: {
            MAXIMAGECOUNT: 4,
            MAXSECONDS: 140,
        },
    },
    PINTEREST: {
        POST: {
            MAXIMAGECOUNT: 1,
            MINWIDTH: 75,
            MINHEIGHT: 75,
            DESCRIPTION_MAX: 800,
        },
    },
    LINKEDIN: {
        POST: {
            MAXIMAGECOUNT: 20,
            MAXSECONDS: 900,
            DESCRIPTION_MAX: 3000,
        }
    },
    GBUSINESS: {
        POST: {
            MAXIMAGECOUNT: 1,
            MAXWIDTH: 3000,
            MAXHEIGHT: 3000,
            MINWIDTH: 250,
            MINHEIGHT: 250,
        }
    },
    TIKTOK: {
        POST: {
            IMAGE: {
                MAXIMAGECOUNT: 10,
                ASPECTRATIO: ["9:16", "3:4"],
                MINWIDTH: 360,
                MINHEIGHT: 360,
                FILESIZE: 20 * 1024 * 1024, // 20MB max
            },
            VIDEO: {
                MAXWIDTH: 4096,
                MAXHEIGHT: 4096,
                MINWIDTH: 360,
                MINHEIGHT: 360,
                MINSECONDS: 1,
                MAXSECONDS: 600,
                ACTIONMAXSECONDS: 60,
                ASPECTRATIO: [
                    "9:16",
                    "3:4",
                    "1:1",
                    "16:9"
                ]
            },
            DESCRIPTION_MAX: 2200,
            TITLE_MAX: 90,
        }
    }
}
export const PostUtil = {
    PostType,
    ServiceName,
    DisplayName,
    RecentPostServiceName,
    DashboardProfileServiceName,
    SupportedFileFormat,
    ServiceConfigurations,
    ProfileType,
    TikTokPrivacyLevel,
    YoutubePrivacyLevel,
    MediaType,
    TikTokOptions
}