import { Stack } from '@mui/material';
import { CommonHelper } from 'src/utils/commonHelper';

export default function TextIconLabel({ icon, value, endIcon = false, sx, ...other }) {
  const formatValue = (val) => {
    if (typeof val === 'number') {
      // Directly format if value is a number
      return CommonHelper.formatNumber(val);
    } else if (typeof val === 'string') {
      if (/^\d+$/.test(val)) {
        return CommonHelper.formatNumber(Number(val));
      }
    }
    return val; // Return as is for other cases
  };
  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={0.3}
      sx={{
        typography: 'body2',
        ...sx,
      }}
      {...other}
    >
      {!endIcon && icon}
      {value && formatValue(value)}
      {endIcon && icon}
    </Stack>
  );
}
