import {
  Grid,
  Typography,
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Paper,
  Stack,
  useMediaQuery,
  useTheme,
  Divider,
} from '@mui/material';
import { CommonHelper } from 'src/utils/commonHelper';
import { FavoriteBorderRounded as FavoriteBorderRoundedIcon, Public as PublicIcon, MoreVert as MoreVertIcon, FiberManualRecord as FiberManualRecordIcon, BookmarkBorder as BookmarkBorderIcon } from '@mui/icons-material';
import TwittercommentIcon from '../../../../assets/comment.svg';
import TwitterviewIcon from '../../../../assets/twitter-views.svg';
import TwitterRetweetIcon from '../../../../assets/twitter_retweet.svg';


const TwitterPostImagesPreview = ({ data }) => {
  let mediaFiles = CommonHelper.ProcessMediaUrl(data?.media_url)?.media_data;
  const isMultipleMedia = mediaFiles.length > 1;
  const mediaHeight = isMultipleMedia ? 150 : 200;
  const mediaStyle = { objectFit: 'cover', width: '100%', height: mediaHeight };
  const imageView = () => {
    let view = [];
    let xWidth = 6;
    const remainingImagesCount = mediaFiles.length > 5 ? mediaFiles.length - 4 : 0;
    switch (mediaFiles.length) {
      case 1:
        xWidth = 12;
        break;
      case 2:
        xWidth = 12;
        break;
      case 3:
      case 4:
        xWidth = 6;
        break;
      default:
        xWidth = 6;
        break;
    }
    view = mediaFiles.slice(0, 4).map((image, i) => (
      <Grid
        item
        xs={mediaFiles.length === 3 && i === 2 ? 12 : xWidth}
        key={i}
        sx={{
          maxWidth: 500,
          maxHeight: 250,
          overflow: 'hidden',
          position: 'relative',
          display: 'inline-flex',
          opacity: 1,
          transform: 'scale(0.99)'
        }}
      >
        {image && image.url ? (
          image.type === 'image' ? (
            <img src={image.url} alt={image.url} style={mediaStyle} />
          ) : (
            <video src={image.url} alt={image.url} style={mediaStyle} />
          )
        ) : (
          <img src={image} alt={image} style={{ ...mediaStyle, height: 200 }} />
        )}
      </Grid>
    ));
    return (
      <Grid container sx={{ position: 'relative' }}>
        {view}
        {remainingImagesCount > 0 && (
          <Typography
            variant="h2"
            color="white"
            style={{
              position: 'absolute',
              top: '75%',
              left: '75%',
              transform: 'translate(-50%, -50%)',
              zIndex: 1,
              width: '208px',
              height: '150px',
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            +{remainingImagesCount}
          </Typography>
        )}
      </Grid>
    );
  };

  const theme = useTheme();
  const respwidth = useMediaQuery(theme.breakpoints.up('xl'));
  const ConnectedProfiles = data.connected_profile_details;
  const ProfileTime = () => {
    return (
      <>
        <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" sx={{ gap: '0.3rem' }}>
          <Typography variant="span">Just Now</Typography>
          <FiberManualRecordIcon sx={{ fontSize: 5, mt: '0.6rem' }} />
          <PublicIcon sx={{ fontSize: 15, mt: '0.3rem' }} />
        </Stack>
      </>
    );
  };

  return (
    <>
      <Stack container justifyContent="center" alignItems="center">
        <Card
          sx={{
            maxWidth: 500,
            minWidth: respwidth ? 400 : null,
            boxShadow: 'none',
            border: 'solid 2px',
            borderColor: theme.palette.preview.postcard.color,
          }}
        >
          <CardHeader
            avatar={
              <Avatar
                src={
                  ConnectedProfiles.connected_profile_image
                    ? ConnectedProfiles.connected_profile_image
                    : ConnectedProfiles.connected_profile_name || ConnectedProfiles.connected_profile_id
                }
                alt={
                  ConnectedProfiles.connected_profile_name
                    ? ConnectedProfiles.connected_profile_name
                    : ConnectedProfiles.connected_profile_id
                }
                sx={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                  height: 40,
                  width: 40,
                  bgcolor: theme.palette.primary.main,
                }}
                style={{ color: 'white' }}
              />
            }
            title={ConnectedProfiles?.connected_profile_name}
            sx={{ fontSize: { xl: 15, lg: 13, md: 11, sm: 9, xs: 7 } }}
            subheader={<ProfileTime />}
            action={<MoreVertIcon sx={{ mt: 1 }} />}
          />
          <CardContent>
            <Typography
              style={{
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2,
                overflow: 'hidden',
                WebkitBoxDecorationBreak: 'clone',
                whiteSpace: 'pre-line',
                textOverflow: 'ellipsis',
              }}
            >
              {CommonHelper.ParseDescription(data.description)}
            </Typography>
          </CardContent>
          <CardMedia sx={{ pl: 2, pr: 2 }}>{imageView()}</CardMedia>
          <CardActions disableSpacing sx={{ display: 'block' }}>
            <Divider />
            <Stack direction="row" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
              <Paper sx={{ display: 'flex', alignItems: 'center', mt: 0.5 }}>
                <img
                  src={TwittercommentIcon}
                  style={{ height: 'auto', width: '100%', maxWidth: '22px', maxHeight: '22px', marginLeft: '1rem' }}
                />
              </Paper>
              <Paper sx={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src={TwitterRetweetIcon}
                  style={{ height: 'auto', width: '100%', maxWidth: '22px', maxHeight: '22px', marginLeft: '0.5rem' }}
                />
              </Paper>
              <Paper sx={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src={TwitterviewIcon}
                  style={{ height: 'auto', width: '100%', maxWidth: '20px', maxHeight: '20px', marginLeft: '0.5rem' }}
                />
              </Paper>
              <Paper sx={{ display: 'flex', alignItems: 'center' }}>
                <BookmarkBorderIcon sx={{ marginRight: '0.5rem', color: theme.palette.preview.postcard.icon }} />
              </Paper>
            </Stack>
          </CardActions>
        </Card>
      </Stack>
    </>
  );
};

export default TwitterPostImagesPreview;
