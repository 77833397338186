import { useState } from "react";
import { useEffect,useRef } from "react";
import HttpServices from "src/services/httpService";
import { integrateModuleURL } from "src/services/urlService";
import { Config } from "src/config";
import { useToaster } from "src/utils/toaster/toasterContext";
import LinkedInPageAuthorizationModal from "./linkedinPageAuthorizationModal";
import LinkedInIcon from '../../../assets/linked_in.svg';
import { styled } from '@mui/material/styles';
import {
    Avatar, Badge, Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemAvatar,
    ListItemButton, ListItemText, Typography
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { CommonHelper } from "src/utils/commonHelper";
import CustomDomainRedirectIframe from '../customDomainIframe';
// var openedWindow;
const LinkedInPageIntegration = (props) => {
    const [isAuthorizationModalOpen, setAuthorizationModal] = useState(true);
    const [isLinkedInAccountModalOpen, setLinkedInAccountModal] = useState(false);
    const [isNoAccountForIntegrate, setNoAccountIntegrated] = useState(false);
    const [pages, setPages] = useState([]);
    const [profileId, setProfileId] = useState("");
    const [selectedPages, setSelectedPages] = useState([]);
    const { showToast } = useToaster();
    const isCustomDomain = CommonHelper.CustomDomain();

    let isProcessing = false;
    let intervalId = null;
    const messageListenerRef = useRef(null);

    useEffect(() => {
        intervalId = setInterval(() => {
            checkAuthCodeReceivedFromLinkedIn();
        }, Config.Common.socialAccountsIntegrationInterval);
        return (() => {
            clearInterval(intervalId);
            cleanupListener();
        })
    }, [])

    const getLinkedPageOauthToken = () => {
        window.open(
            `${Config.LinkedIn.authorizeUrl}?response_type=code&client_id=${Config.LinkedIn.clientId}&scope=${Config.LinkedIn.scope}&state=${Config.LinkedIn.state}&redirect_uri=${Config.LinkedIn.redirectUri}`, "_blank"
        );
    };

    const cleanupListener = () => {
        if (messageListenerRef.current) {
            window.removeEventListener("message", messageListenerRef.current, false);
            messageListenerRef.current = null; // Reset the ref
        }
    };

    const checkAuthCodeReceivedFromLinkedIn = () => {
        if (isCustomDomain) {
            const handleMessage = (event) => {
                const { type, code } = event.data;
                if (type === "linkedin_code" && code && !isProcessing) {
                    isProcessing=true;
                    cleanupListener(); // Remove event listener
                    clearInterval(intervalId); // Stop polling
                    getLinkedInPages(code); // Process the code
                }
            };
    
            if (!messageListenerRef.current) {
                window.addEventListener("message", handleMessage, false);
                messageListenerRef.current = handleMessage; // Save the reference
            }
        } else {
        var code = localStorage.getItem("linkedin_code");
        var error = localStorage.getItem("linkedin_error");
        if (code) {
            localStorage.removeItem("linkedin_code");
            clearInterval(intervalId);
            getLinkedInPages(code);
        }
        else if (error) {
            localStorage.removeItem("linkedin_error");
            clearInterval(intervalId);
            showToast(error, 'e');
            handleClose();
        }
      }
    };
    const linkedPagesSuccessCallback = (data, message) => {
        if (data.pages && data.pages.length > 0) {
            let isNoAccountForIntegrate = !data.pages.some(a => !a.is_integrated);
            setNoAccountIntegrated(isNoAccountForIntegrate);
        }
            setAuthorizationModal(false);
            setLinkedInAccountModal(true);
            setProfileId(data.id);
            setPages(data.pages);
       
    }
    const linkedPagesFailureCallback = (message) => {
        showToast(message, 'e');
        handleClose();
    }
    const getLinkedInPages = (code) => {
        let linkedInForm = {
            code,
        };
        let postProps = {
            url: integrateModuleURL.getLinkedInPages,
            body: linkedInForm,
            successCallback: linkedPagesSuccessCallback,
            failureCallback: linkedPagesFailureCallback
        }
        HttpServices.Post(postProps);
    };

    const handleClose = () => {
        props.handleClose();
        cleanupListener();
    }

    const linkedInSuccessCallback = (data, message) => {
        showToast(message, 's');
        handleClose();
    }
    const linkedInFailureCallback = (message) => {
        showToast(message, 'e');
        handleClose();
    }
    const handleSubmitPages = () => {
        let linkedInForm = {
            pages: selectedPages,
            id: profileId
        };
        let postProps = {
            url: integrateModuleURL.sendLinkedInPageAccessToken,
            body: linkedInForm,
            successCallback: linkedInSuccessCallback,
            failureCallback: linkedInFailureCallback
        }
        HttpServices.Post(postProps);
    };
    const checkAccountIsIntegrated = (account) => {
        let isChecked = false;
        if (account.is_integrated)
            isChecked = true;
        else {
            let selectedPageList = [...selectedPages];
            if (selectedPageList && selectedPageList.length > 0) {
                let index = selectedPageList.findIndex(a => a.id === account.id);
                if (index >= 0)
                    isChecked = true;
            }
        }
        return isChecked;
    }
    const handlePages = (pageId) => {
        let selectedPageList = [...selectedPages];
        let index = selectedPageList.findIndex((a) => a.id === pageId);
        if (index >= 0) selectedPageList.splice(index, 1);
        else {
            let selectedPage = pages.find((a) => a.id === pageId);
            selectedPageList.push(selectedPage);
        }
        setSelectedPages(selectedPageList);
    };
    const SmallAvatar = styled(Avatar)(({ theme }) => ({
        width: 20,
        height: 20,
        backgroundColor: '#fff',
    }));
    return (
        <>
            {isAuthorizationModalOpen &&
                <LinkedInPageAuthorizationModal
                    handleConnectLinkedInPage={getLinkedPageOauthToken}
                    handleClose={handleClose}
                />}
            {isLinkedInAccountModalOpen ? (
                <Dialog onClose={handleClose} open={isLinkedInAccountModalOpen} fullWidth>
                    <DialogTitle>LinkedIn Pages</DialogTitle>
                    <DialogContent dividers>
                        <div className="l2s-space-between">
                        <Box sx={{
                                 minHeight: '220px',
                                 }}>
                                {pages && pages.length > 0 ?
                                    <>
                                        <List>
                                            {pages.map((a) => {
                                                return (
                                                    <ListItem
                                                        secondaryAction={
                                                            <Checkbox
                                                                edge="end"
                                                                value={a.id}
                                                                checked={checkAccountIsIntegrated(a)}
                                                                onClick={isNoAccountForIntegrate ? null : () => { handlePages(a.id) }}
                                                                disabled={a.is_integrated}
                                                            />
                                                        }
                                                        disablePadding
                                                    >
                                                        <ListItemButton onClick={isNoAccountForIntegrate ? null : () => { handlePages(a.id) }}  disabled={a.is_integrated}>
                                                            <ListItemAvatar>
                                                                <Badge
                                                                    overlap="circular"
                                                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                                    badgeContent={
                                                                        <SmallAvatar alt="" src={LinkedInIcon} />
                                                                    }
                                                                >
                                                                    <Avatar
                                                                        src={a.profile_image ? a.profile_image : a.name || a.id}
                                                                        alt={a.name ? a.name : a.id}
                                                                        style={{ color: '#ffffff' }}
                                                                    />
                                                                </Badge>
                                                            </ListItemAvatar>
                                                            <ListItemText primary={a.name} />
                                                        </ListItemButton>
                                                    </ListItem>
                                                )
                                            })}
                                        </List>
                                        {isNoAccountForIntegrate && <Typography
                                            sx={{
                                                color: 'red',
                                                fontSize: '12px',
                                                marginTop: '10px'
                                            }}
                                        >
                                            All of your accounts are integrated
                                        </Typography>}
                                    </>
                                    :
                                    <Typography sx={{
                                        color: 'red',
                                        margin: '10px'
                                    }}>
                                        There is no LinkedIn pages to integrate
                                    </Typography>
                                }
                            </Box>
                            {isCustomDomain && <CustomDomainRedirectIframe serviceName="linkedin"/> }
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button  color='error' variant="outlined" onClick={handleClose}>Cancel</Button>
                        {pages && pages.length > 0 && !isNoAccountForIntegrate &&
                            <LoadingButton disabled={selectedPages.length === 0} autoFocus onClick={handleSubmitPages}>
                                Submit
                            </LoadingButton>}
                    </DialogActions>
                </Dialog>
            ) : null}
        </>
    )
};
export default LinkedInPageIntegration;
