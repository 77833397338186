import React, { useState } from 'react';
import HttpServices from 'src/services/httpService';
import { integrateModuleURL } from 'src/services/urlService';
import { useEffect } from 'react';
import LoadFacebookSdk from '../loadFacebookSdk';
import { useToaster } from 'src/utils/toaster/toasterContext';
import CustomDomainRedirectIframe from '../customDomainIframe';
import { CommonHelper } from 'src/utils/commonHelper';
import FacebookPageAuthorizationModal from '../facebookIntegration/facebookPageAuthorizationModal';

import { Config } from 'src/config';

const FacebookReAuthenticate = ({ AccountId, handleClose ,getConnectedNetworks}) => {
    const [isAuthorizationModalOpen, setAuthorizationModal] = useState(true);
    const [isLoadSdk, setLoadSdk] = useState(false);
    const { showToast } = useToaster();
    const [isLoading, setLoading] = useState(false);
    const isCustomDomain = CommonHelper.CustomDomain();
    let intervalId = null;
    let isProcessing = false;

    useEffect(() => {
        intervalId = setInterval(() => {
            checkAuthoCodeReceivedFromFacebook();
        }, Config.Common.socialAccountsIntegrationInterval);
        return () => {
            clearInterval(intervalId);
        };
    }, [])

    const checkAuthoCodeReceivedFromFacebook = () => {

        if (isCustomDomain) {
            const handleMessage = (event) => {
                const { type, code } = event.data;
                if (type === "fb_auth_code" && code && !isProcessing) {
                    isProcessing=true;
                    window.removeEventListener("message", handleMessage, false); // Remove event listener
                    clearInterval(intervalId); // Stop polling
                    getFacebookPagesAndGroups(code); // Process the code
                }
            };
    
            window.addEventListener("message", handleMessage, false);
        } else {
        var code = localStorage.getItem("fb_autho_code");
        var error = localStorage.getItem("fb_error");
        if (code) {
            localStorage.removeItem("fb_autho_code");
            clearInterval(intervalId);
            getFacebookPagesAndGroups(code);
        }
        else if (error) {
            localStorage.removeItem("fb_error");
            clearInterval(intervalId);
            showToast(error, 'e');
        }
    }
    }
    const fbPagesAndGroupsSuccessCallback = (data, message) => {
        showToast(message, 's');
        getConnectedNetworks();
        handleClose();
    }
    const fbPagesAndGroupsFailureCallback = (message) => {
        showToast(message, 'e');
        handleClose();
    }
    const getFacebookPagesAndGroups = (code) => {
        let facebookForm = {
            code,
            account_id:AccountId
        };
        let postProps = {
            url: integrateModuleURL.reAuthenticateFacebookPagesAndGroups,
            body: facebookForm,
            successCallback: fbPagesAndGroupsSuccessCallback,
            failureCallback: fbPagesAndGroupsFailureCallback
        }
        HttpServices.Post(postProps);
    };
    const handleConnectFacebook = () => {
        setLoadSdk(true);
    }

    return (
        <>
            {isAuthorizationModalOpen &&
                <FacebookPageAuthorizationModal
                    handleConnectFacebook={handleConnectFacebook}
                    handleClose={handleClose}
                />}
            {isLoadSdk && <LoadFacebookSdk />}
            {isCustomDomain && <CustomDomainRedirectIframe serviceName="facebook"/> }
        </>
    );
};
export default FacebookReAuthenticate;
