import { Box, Card } from '@mui/material';
import { useEffect, useState } from 'react';
import HttpServices from 'src/services/httpService';
import { postsModuleURL } from 'src/services/urlService';
import Spinner from 'src/utils/Loader/spinner';
import { useToaster } from 'src/utils/toaster/toasterContext';
import NoUpcomingPost from '../../assets/app-image/no-upcoming-posts.svg';
import ScheduledPostGrid from './Grid/PostGrid.component';
import ScheduledPostFilter from './scheduledPostFilter.component';

const ScheduledPost = () => {

  const { showToast } = useToaster();

  //State variables
  const [scheduledPostList, setScheduledPostList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("scheduled_time");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  useEffect(() => {
    let currentDate = new Date();
    let tDate = currentDate.setDate(currentDate.getDate() + 365);
    currentDate.setHours(0, 0, 0, 0);
    tDate = new Date(currentDate);
    setFromDate(new Date());
    setToDate(tDate);
  }, []);

  useEffect(() => {
    if (fromDate && toDate) {
      getScheduledPost();
    }
  }, [order, orderBy, fromDate, toDate])

  const postSuccessCallback = (data, message) => {
    message && showToast(message, 's');
    let filteredData = data.filter((data) => data.connected_profile_details?.length > 0 )
    setScheduledPostList(filteredData);
    setLoader(false);
  };

  const failureCallback = (message) => {
    message && showToast(message, 'e');
    setLoader(false);
  };

  const getScheduledPost = () => {
    let getProps = {
      url: `${postsModuleURL.getApprovedCalendarEvents
        }${fromDate.toISOString()}&to=${toDate.toISOString()}&sort_by=${orderBy}&sort_order=${order}`,
      successCallback: postSuccessCallback,
      failureCallback,
    };
    HttpServices.Get(getProps);
  };

  const handleReCallScheduledPost = (sortBy = '', sortOrder = '') => {
    setLoader(true);
    setOrder(sortOrder);
    setOrderBy(sortBy);
    getScheduledPost();
  };

  const handleApplyFilter = (fromDate, toDate) => {
    setFromDate(fromDate);
    setToDate(toDate);
  }

  return (
    <Box>
      {loader ? (
        <Card style={{ minHeight: 'calc(100vh - 150px)', maxHeight: 'calc(100vh - 150px)' }}>
          <Spinner />
        </Card>
      ) : (
        <>
          <Card sx={{ mb: 2, p: 2, overflowY: 'auto', visibility: true }} >
            <ScheduledPostFilter handleApplyFilter={handleApplyFilter} fromDate={fromDate} toDate={toDate} />
          </Card>
          {scheduledPostList && scheduledPostList.length > 0 ? (
            <Card>
              <ScheduledPostGrid
                scheduledPostList={scheduledPostList}
                orderBy={orderBy}
                order={order}
                handleReCallScheduledPost={handleReCallScheduledPost}
              />
            </Card>
          ) : (
            <Card style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 750, minHeight: 'calc(100vh - 150px)', maxHeight: 'calc(100vh - 150px)' }}>
              <img src={NoUpcomingPost} style={{ width: '50%', height: '50%', maxWidth: '50%', maxHeight: '50%' }} alt="No Upcoming Post" />
            </Card>
          )}
        </>
      )}
    </Box>
  );
};
export default ScheduledPost;
