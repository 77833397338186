import { Avatar, Badge, Typography } from '@mui/material';
import ProfileCard from 'src/components/profile-card/profile-card.component';
import CarouselComponent from './carousel/carousel.component';
import { CommonHelper } from './commonHelper';

const getPostMedias = (media_urls, mediaHeight = 5) => {
  let isMediaAvailable = media_urls && media_urls.length > 0;
  return isMediaAvailable ? (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: '0.25rem',
        justifyContent: 'space-between',
        marginTop: '0.25rem',
        width: mediaHeight + 'rem',
        height: mediaHeight + 'rem',
      }}
    >
      <CarouselComponent media_urls={media_urls} />
    </div>
  ) : null;
};

const calenderGetPostMedias = (media_urls,mediaHeight = 4) => {
  let isMediaAvailable = media_urls && media_urls.length > 0;
  return isMediaAvailable ? (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: '0.25rem',
        justifyContent: 'space-between',
        marginTop: '0.25rem',
        height: '4rem',
      }}
    >
      <CarouselComponent media_urls={media_urls} />
    </div>
  ) : null;
};



const getPublicPostMedias = (media_urls, mediaHeight = 15) => {
  let isMediaAvailable = media_urls && media_urls.length > 0;
  return isMediaAvailable ? (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: '0.25rem',
        justifyContent: 'space-between',
        marginTop: '0.25rem',
      }}
    >
      <CarouselComponent media_urls={media_urls} />
    </div>
  ) : null;
};

const GetPostContent = (description, cardMaxWidth) => {
  return (
    description && (
      <Typography
        variant="body2"
        sx={{
          display: '-webkit-box',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          WebkitLineClamp: 3,
          WebkitBoxOrient: 'vertical',
          whiteSpace: 'normal',
          maxHeight: `${3 * 1.5}em`,
          maxWidth: cardMaxWidth ? cardMaxWidth : 400,
        }}
      >
        {description}
      </Typography>
    )
  );
};

const getProfile = (name = '', email = '', profilePic = '') => {
  return (
    <CommonHelper.HtmlTooltip
      title={<ProfileCard primaryText={name} secondaryText={email} imageUrl={profilePic} />}
      placement="bottom"
    >
      <Avatar
        alt={name}
        src={profilePic || ''}
        sx={{ width: 35, height: 35, marginRight: 2, bgcolor: 'primary.main', mr: 1 }}
      >
        {!profilePic && name.charAt(0).toUpperCase()}
      </Avatar>
    </CommonHelper.HtmlTooltip>
  );
};

const getSocialMediaViewWithBadge = (connectedProfile, serviceName) => {
  return (
    <Badge
      overlap="circular"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      badgeContent={CommonHelper.GetSocialMediaAvatar(serviceName)}
      sx={{ zIndex: 0 }}
    >
      <Avatar
        src={
          connectedProfile?.connected_profile_image
            ? connectedProfile?.connected_profile_image
            : connectedProfile?.connected_profile_name?.charAt(0)?.toUpperCase()
        }
        alt={connectedProfile?.connected_profile_name}
        sx={{ height: 25, width: 25, bgcolor: 'primary.main' }}
      />
    </Badge>
  );
};

const getSocialMediaViewWithTooltip = (connectedProfiles) => {
  const isManyProfiles = connectedProfiles.length > 8;
  const avatarSize = isManyProfiles ? 25 : 25; // Adjust the avatar size based on the number of profiles

  return connectedProfiles && connectedProfiles.length > 0 ? (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: '0.75rem',
        margin: '0.5rem 0',
        border: '1px dashed #9ea735',
        width: '100%',
        padding: '10px',
        borderRadius: '5px',
        maxHeight: '150px', // Set a fixed height
        overflowY: 'auto', // Enable vertical scrolling if needed
      }}
    >
      {connectedProfiles.map((connectedProfile, index) => {
        let serviceName = connectedProfile?.social_media || connectedProfile?.service_name;
        return (
          <CommonHelper.HtmlTooltip
            key={index} // Add a unique key for each element
            title={
              <ProfileCard
                primaryText={connectedProfile?.connected_profile_name || connectedProfile?.profile_name}
                secondaryText={connectedProfile?.social_media || connectedProfile?.service_name}
                imageUrl={connectedProfile?.connected_profile_image || connectedProfile?.profile_image}
              />
            }
            placement="bottom"
          >
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              badgeContent={CommonHelper.GetSocialMediaAvatar(serviceName)}
              sx={{
                zIndex: 0,
                '& .MuiBadge-badge': {
                  height: 20,
                  width: 20,
                  minWidth: 20,
                  borderRadius: '50%',
                  border: '2px solid white', // adds a border for better separation
                  margin: '-4px', // adds slight spacing between badge and avatar
                },
              }}
            >
              <Avatar
                src={connectedProfile.connected_profile_image || connectedProfile?.profile_image}
                alt={connectedProfile.connected_profile_name || connectedProfile?.profile_name}
                sx={{ height: avatarSize, width: avatarSize, bgcolor: 'primary.main' }}
              />
            </Badge>
          </CommonHelper.HtmlTooltip>
        );
      })}
    </div>
  ) : null;
};

const getSocialMediaProfiles = (connectedProfiles) => {
  const isManyProfiles = connectedProfiles.length > 8;
  const avatarSize = isManyProfiles ? 20 : 25; // Adjust the avatar size based on the number of profiles

  return connectedProfiles && connectedProfiles.length > 0 ? (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: '0.75rem',
      }}
    >
      {connectedProfiles.map((connectedProfile, index) => {
        const serviceName = connectedProfile?.social_media || connectedProfile?.service_name;

        return (
          <CommonHelper.HtmlTooltip
            key={index} // Add a unique key for each element
            title={
              <ProfileCard
                primaryText={connectedProfile?.connected_profile_name || connectedProfile?.profile_name}
                secondaryText={serviceName}
                imageUrl={connectedProfile?.connected_profile_image || connectedProfile?.profile_image}
              />
            }
            placement="bottom"
          >
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              badgeContent={CommonHelper.GetSocialMediaAvatar(serviceName)}
              sx={{ zIndex: 0 }}
            >
              <Avatar
                src={connectedProfile.connected_profile_image || connectedProfile?.profile_image}
                alt={connectedProfile.connected_profile_name || connectedProfile?.profile_name}
                sx={{ height: avatarSize, width: avatarSize, bgcolor: 'primary.main' }}
              />
            </Badge>
          </CommonHelper.HtmlTooltip>
        );
      })}
    </div>
  ) : null;
};

export const PostDetailsHelper = {
  getProfile,
  getPostMedias,
  calenderGetPostMedias,
  getPublicPostMedias,
  GetPostContent,
  getSocialMediaViewWithBadge,
  getSocialMediaViewWithTooltip,
  getSocialMediaProfiles
};
