import {
  Grid,
  Typography,
  Avatar,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Stack,
  useMediaQuery,
  useTheme,
  Badge,
} from '@mui/material';
import { CommonHelper } from 'src/utils/commonHelper';
import { Public as PublicIcon, MoreVert as MoreVertIcon, FiberManualRecord as FiberManualRecordIcon, Share as ShareIcon } from '@mui/icons-material';
import GbusinessVerifyUser from '../../../../assets/gbusiness-verify-user.svg';
import { styled } from '@mui/material/styles';

const HoverLink = styled('a')(({ theme }) => ({
  textDecoration: 'none',
  color: 'inherit',
  '&:hover': {
    textDecoration: 'underline',
    color: theme.palette.primary.main
  },
}));

const eventFormatDate = (dateString) => {
  const date = new Date(dateString);
  const options = { day: 'numeric', month: 'short' };
  return new Intl.DateTimeFormat('en-US', options).format(date);
};

const GBusinessEventPreview = ({ data }) => {
  let mediaFiles = CommonHelper.ProcessMediaUrl(data?.media_url)?.media_data;
  const eventFormattedStartDate = eventFormatDate(data.start_date);
  const eventFormattedEndDate = eventFormatDate(data.end_date);
  const imageView = () => {
    let xWidth = 12

    return (
      <Grid container sx={{ position: 'relative' }}>
        <Grid
          item
          xs={xWidth}
          sx={{
            maxWidth: 500,
            maxHeight: 250,
            overflow: 'hidden',
            position: 'relative',
            display: 'inline-flex',
            opacity: 1,
            transform: 'scale(0.99)'
          }}
        >
          {mediaFiles && mediaFiles[0]?.url && (
            <>
              <img
                src={mediaFiles[0].url}
                alt={mediaFiles[0].url}
                width={'100%'}
                height={200}
                style={{ objectFit: 'cover', borderRadius: '10px' }}
              />
            </>
          )}
        </Grid>
      </Grid>
    );
  };

  const theme = useTheme();
  const respwidth = useMediaQuery(theme.breakpoints.up('xl'));
  const ConnectedProfiles = data.connected_profile_details;
  const ProfileTime = () => {
    return (
      <>
        <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" sx={{ gap: '0.3rem' }}>
          <Typography variant="span">Just Now</Typography>
          <FiberManualRecordIcon sx={{ fontSize: 5, mt: '0.6rem' }} />
          <PublicIcon sx={{ fontSize: 15, mt: '0.3rem' }} />
        </Stack>
      </>
    );
  };

  return (
    <>
      <Stack container justifyContent="center" alignItems="center" >
        <Card
          sx={{
            maxWidth: 500,
            minWidth: respwidth ? 450 : null,
            boxShadow: 'none',
            border: 'solid 2px',
            height: 'auto',
            borderColor: theme.palette.preview.postcard.color,
          }}
        >
          <CardHeader
            avatar={
              <Badge overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} badgeContent={<img src={GbusinessVerifyUser} sx={{ width: 20, height: 20, overflow: 'hidden', background: 'white', color: theme.palette.preview.postcard.gbverifyicon, ml: 1, mb: 1 }} />}>
                <Avatar
                  src={
                    ConnectedProfiles.connected_profile_image
                      ? ConnectedProfiles.connected_profile_image
                      : ConnectedProfiles.connected_profile_name || ConnectedProfiles.connected_profile_id
                  }
                  sx={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    height: 40,
                    width: 40,
                    bgcolor: theme.palette.primary.main,
                  }}
                  style={{ color: 'white' }}
                />
              </Badge>
            }
            title={ConnectedProfiles?.connected_profile_name}
            sx={{ fontSize: { xl: 15, lg: 13, md: 11, sm: 9, xs: 7 } }}
            subheader={<ProfileTime />}
            action={<MoreVertIcon sx={{ mt: 1 }} />}
          />
          {mediaFiles && <CardMedia sx={{ pl: 2, pr: 2, mt: 2 }}>{imageView()}</CardMedia>}
          <CardContent disableSpacing sx={{ display: 'block' }}>
            <Typography sx={{fontSize: '1.5rem',mt:'-1.5rem'}}>{data.title}</Typography>
            <Typography sx={{fontSize: '0.8rem',mb:2}}>{eventFormattedStartDate} - {eventFormattedEndDate}</Typography>
            <Typography
              style={{
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2,
                overflow: 'hidden',
                WebkitBoxDecorationBreak: 'clone',
                whiteSpace: 'pre-line',
                textOverflow: 'ellipsis',
              }}
            >
              {CommonHelper.ParseDescription(data.description)}
            </Typography>
            {data.button_type && data.url && <>
              <HoverLink href={data.url} target="_blank">
                <Typography variant='body1' sx={{ color: theme.palette.primary.main }}>{data.button_type}</Typography>
              </HoverLink>
            </>}
            <ShareIcon sx={{ position: 'absolute', bottom: 10, right: 10 }} />
          </CardContent>
        </Card>
      </Stack>
    </>
  );
};

export default GBusinessEventPreview;
