import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from "@mui/material";
import { UserListHead } from "src/sections/@dashboard/user";


const ScheduledPostGridHead = ({ scheduledPostList, order, orderBy, handleRequestSort }) => {
    const getTableDesk = [
        { id: 'scheduled_time',width:'5%', label: 'Scheduled', alignRight: false, hasSort: true },
        { id: 'created_time',width:'5%', label: 'Created', alignRight: false, hasSort: true },
        { id: 'updated_at',width:'5%', label: 'Updated', alignRight: false, hasSort: true },
        { id: 'post_status',width:'10%', label: 'Post Status', alignRight: false, hasSort: false },
        { id: 'description',width:'10%', label: 'Post  Content', alignRight: false, hasSort: true },
        { id: 'media',width:'10%', label: 'Media', alignRight: false },
        { id: 'created_by_name',width:'10%', label: 'Created By', alignRight: false, hasSort: true },
        { id: 'approved_by_name',width:'10%', label: 'Approved By', alignRight: false, hasSort: true },
        { id: 'is_enabled',width:'10%', label: 'Status', alignRight: false, hasSort: true },
        { id: 'scheduled-for',width:'25%', label: 'Scheduled For', alignRight: false },
        { id: 'action',width:'10%', label: 'Action', alignCenter: true },
    ];
    const getTableMobile = [
        { id: 'scheduled-on', label: 'Scheduled On', alignRight: false, hasSort: true },
        { id: 'post-content', label: 'post-content', alignRight: false, hasSort: true },
        { id: 'action', label: 'Action', alignRight: false },
    ];
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const TABLE_HEAD = isMobile ? getTableMobile : getTableDesk;


    return (
        <UserListHead
            order={order}
            orderBy={orderBy}
            headLabel={TABLE_HEAD}
            rowCount={scheduledPostList.length}
            onRequestSort={handleRequestSort}
            style={{ whiteSpace: 'nowrap' }}
            isShowCheckbox={false}
        />
    )
}

export default ScheduledPostGridHead;