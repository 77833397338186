import {
  Box,
  Card,
  CardContent,
  Avatar,
  Typography,
  IconButton,
  SvgIcon,
  useTheme,
  Stack,
  useMediaQuery,
  Paper,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { CommonHelper } from 'src/utils/commonHelper';
import {
  FavoriteBorderRounded as FavoriteBorderRoundedIcon,
  Public as PublicIcon,
  PlayArrow as PlayArrowIcon,
  MoreHoriz as MoreHorizIcon,
  FiberManualRecord as FiberManualRecordIcon,
  BookmarkBorder as BookmarkBorderIcon,
  IosShare as IosShareIcon,
  StarBorder as StarBorderIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from '@mui/icons-material';
import { useRef, useState, useEffect } from 'react';

const GradientOverlay = styled('div')({
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '48px',
  height: '48px',
});

const PlayButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '48px',
  height: '48px',
  backgroundColor: '#fff',
  borderRadius: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 0,
}));

const FacebookReelsPreview = ({ data }) => {
  const theme = useTheme();
  const videoRef = useRef(null);
  const respwidth = useMediaQuery(theme.breakpoints.up('xl'));
  const [isRellsPlaying, setRellsPlaying] = useState(false);
  const [showControls, setShowControls] = useState(false);
  const ConnectedProfiles = data.connected_profile_details;

  useEffect(() => {
    return () => {
      if (videoRef.current) {
        videoRef.current.pause();
        videoRef.current.currentTime = 0;
      }
    };
  }, []);

  const handlePlayButtonClick = () => {
    if (videoRef.current) {
      videoRef.current.play().catch(error => {
      });
      setShowControls(true);
    }
  };

  const handleVideoPlay = () => {
    setRellsPlaying(true);
  };
  const handleVideoPause = () => {
    setRellsPlaying(false);
  };

  return (
    <>
      <Stack container alignItems="center" justifyContent="center" >
        <Card sx={{ width: 300, maxWidth: 500, minWidth: respwidth ? 300 : 200, boxShadow: 'none', border: 'solid 2px', borderColor: theme.palette.preview.postcard.color }}>
          <Box sx={{ position: 'relative' }}>
            {data.media_url && (
              <video
                preload="auto"
                crossOrigin="anonymous"
                src={data.media_url}
                width="100%"
                height="100%"
                style={{ objectFit: 'cover', height: 460, width: 300, maxWidth: 500, minWidth: respwidth ? 300 : 200 }}
                ref={videoRef}
                controls={showControls}
                onPlay={handleVideoPlay}
                onPause={handleVideoPause}
              />
            )}
            {!isRellsPlaying && (
              <PlayButton onClick={handlePlayButtonClick}>
                <PlayArrowIcon sx={{ fontSize: 40 }} />
              </PlayButton>
            )}
            <GradientOverlay />
          </Box>

          <Box sx={{ alignSelf: 'flex-start', p: 1.3 }}>
            <Stack direction="row" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
              <Paper sx={{ display: 'flex', alignItems: 'center', gap: '0.4rem', mb: 1 }}>
                <MoreHorizIcon fontSize="small" />
                <IosShareIcon fontSize='small' />
                <StarBorderIcon fontSize='small' />
              </Paper>
              <Paper sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <Box sx={{ p: 1, borderRadius: '50px', display: 'flex' }}>
                  <Typography sx={{}}>Board</Typography>
                  <KeyboardArrowDownIcon />
                </Box>
                <Box sx={{ p: 1, backgroundColor: 'error.main', borderRadius: '50px' }}>
                  <Typography sx={{ color: 'white' }}>Save</Typography>
                </Box>
              </Paper>
            </Stack>
            <Typography
              style={{
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 1,
                overflow: 'hidden',
                WebkitBoxDecorationBreak: 'clone',
                whiteSpace: 'pre-line',
                textOverflow: 'ellipsis',
                fontSize: '0.9rem',
                color: 'black',
                textDecoration: 'underline',
                marginBottom: '8px',
              }}
            >
              {data.link}
            </Typography>

            <Typography
              style={{
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 1,
                overflow: 'hidden',
                WebkitBoxDecorationBreak: 'clone',
                whiteSpace: 'pre-line',
                textOverflow: 'ellipsis',
                fontSize: '1.3rem',
                color: 'black',
                fontWeight: 700
              }}
            >
              {data.title}
            </Typography>

            <Typography
              style={{
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 1,
                overflow: 'hidden',
                WebkitBoxDecorationBreak: 'clone',
                whiteSpace: 'pre-line',
                textOverflow: 'ellipsis',
                fontSize: '0.8rem',
                color: 'black'
              }}
            >
              {CommonHelper.ParseDescription(data.description)}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                mt: 2,
              }}
            >
              <Box sx={{ display: 'flex', gap: '0.3rem' }}>
                <Avatar
                  src={
                    ConnectedProfiles.connected_profile_image
                      ? ConnectedProfiles.connected_profile_image
                      : ConnectedProfiles.connected_profile_name || ConnectedProfiles.connected_profile_id
                  }
                  alt={
                    ConnectedProfiles.connected_profile_name
                      ? ConnectedProfiles.connected_profile_name
                      : ConnectedProfiles.connected_profile_id
                  }
                  sx={{
                    height: 25,
                    width: 25,
                    bgcolor: theme.palette.primary.main,
                  }}
                />
                <Typography
                  variant="body2"
                  style={{
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 1,
                    overflow: 'hidden',
                    WebkitBoxDecorationBreak: 'clone',
                    whiteSpace: 'pre-line',
                    textOverflow: 'ellipsis',
                    fontSize: '1rem',
                    fontWeight: '700'
                  }}
                >
                  {data?.connected_profile_details?.connected_profile_name}
                </Typography>
              </Box>
              <Box sx={{ p: 1, backgroundColor: 'background.shadow', borderRadius: '50px' }}>
                <Typography >Follow</Typography>
              </Box>
            </Box>
          </Box>
        </Card>
      </Stack>
    </>
  );
};

export default FacebookReelsPreview;
