import { useEffect,useRef } from "react";
import { Config } from "src/config";
import HttpServices from "src/services/httpService";
import { integrateModuleURL } from "src/services/urlService";
import PinterestAuthorizationModal from "./pinterestAuthorizationModal";
import { useState } from "react";
import { useToaster } from "src/utils/toaster/toasterContext";
import { CommonHelper } from "src/utils/commonHelper";
import CustomDomainRedirectIframe from '../customDomainIframe';

const PinterestIntegration = (props) => {
  const [isAuthorizationModalOpen, setAuthorizationModal] = useState(true);
  const { showToast } = useToaster();
  const isCustomDomain = CommonHelper.CustomDomain();
  let intervalId = null;
  let isProcessing = false;
  const messageListenerRef = useRef(null);

  useEffect(() => {
    intervalId = setInterval(() => {
      checkAccessTokenReceivedFromPinterest();
    }, Config.Common.socialAccountsIntegrationInterval);
    return () => {
      clearInterval(intervalId);
      cleanupListener();
    };
  }, [])

  const getPinterestOauthToken = () => {
    window.open(
      `${Config.Pinterest.authorizeUrl}?client_id=${Config.Pinterest.clientId}&scope=${Config.Pinterest.scope}&redirect_uri=${Config.Pinterest.redirectUri}&response_type=code`
    );
  }
  const cleanupListener = () => {
    if (messageListenerRef.current) {
        window.removeEventListener("message", messageListenerRef.current, false);
        messageListenerRef.current = null; // Reset the ref
    }
};
  const checkAccessTokenReceivedFromPinterest = () => {
    if (isCustomDomain) {
      const handleMessage = (event) => {
          const { type, code } = event.data;
          if (type === "pinterest_code" && code && !isProcessing) {
              isProcessing = true
              cleanupListener();
              clearInterval(intervalId); // Stop polling
              sendAccessTokenToServer(code); // Process the code
          }
      };

      if (!messageListenerRef.current) {
        window.addEventListener("message", handleMessage, false);
        messageListenerRef.current = handleMessage; // Save the reference
    }
  } else {
    var code = localStorage.getItem("pinterest_code");
    var error = localStorage.getItem("pinterest_error");
    if (code) {
      localStorage.removeItem("pinterest_code");
      clearInterval(intervalId);
      sendAccessTokenToServer(code);
    }
    else if (error) {
      localStorage.removeItem("pinterest_error");
      clearInterval(intervalId);
      showToast(error, 'e');
    }
  }
  };
  const pinterestSuccessCallback = (data, message) => {
    showToast(message, 's');
    handleClose();
  }
  const pinterestFailureCallback = (message) => {
    showToast(message, 'e');
    handleClose();
  }
  const sendAccessTokenToServer = (code) => {
    let pinterestForm = {
      code
    };
    let postProps = {
      url: integrateModuleURL.sendPinterestAccessToken,
      body: pinterestForm,
      successCallback: pinterestSuccessCallback,
      failureCallback: pinterestFailureCallback
    }
    HttpServices.Post(postProps);
  };

  const handleClose = () => {
    props.handleClose();
    cleanupListener();
  }

  return (
    <>
   {isAuthorizationModalOpen &&
    <PinterestAuthorizationModal
      handleConnectPinterest={getPinterestOauthToken}
      handleClose={handleClose}
    />}
    {isCustomDomain && <CustomDomainRedirectIframe serviceName="pinterest"/> }

    </>
  );
};
export default PinterestIntegration;