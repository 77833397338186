import { useEffect, useRef } from 'react';

const LinkedInRedirect = () => {
    const intervalRef = useRef(null);
    const popupWindowURL = new URL(window.location.href);
    const code = popupWindowURL.searchParams.get("code");
    const error = popupWindowURL.searchParams.get("error");
    const errorDescription = popupWindowURL.searchParams.get("error_description");
    useEffect(() => {
        if (code || error) {
            // Store code or error in localStorage
            if (code) {
                localStorage.setItem("linkedin_code", code);
            } else {
                localStorage.setItem("linkedin_error", errorDescription || error);
            }

            // Notify parent window and close
            notifyParent(code || null);
            window.close();
            return; // Prevent interval setup if code/error is already handled
        }

        const checkAuthCode = () => {
            const storedCode = localStorage.getItem("linkedin_code");
            if (storedCode) {
                notifyParent(storedCode);
                clearInterval(intervalRef.current);
            }
        };
        intervalRef.current = setInterval(checkAuthCode, 5000);
        return () => {
            clearInterval(intervalRef.current);
        };
    }, [code, error, errorDescription]);

    const notifyParent = (authCode) => {
        const message = { type: "linkedin_code", code: authCode };

        try {
            const iframe = document.getElementById("iframe-id");
            if (iframe?.contentWindow) {
                iframe.contentWindow.postMessage(message, "*");
            } else if (window.opener) {
                window.opener.postMessage(message, "*"); 
            }
        } catch (e) {
            console.error("Failed to send message to parent window:", e);
        }
    };

    return null;
}
export default LinkedInRedirect;