import { LoadingButton } from '@mui/lab';
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Typography, ListItem, ListItemButton, List, ListItemAvatar, Badge, Avatar, ListItemText } from '@mui/material';
import { useEffect, useState, useRef } from 'react';
import HttpServices from 'src/services/httpService';
import { integrateModuleURL } from 'src/services/urlService';
import LoadFacebookSdk from '../loadFacebookSdk';
import InstagramAuthorizationModal from './instagramAuthorizationModal';
import { useToaster } from 'src/utils/toaster/toasterContext';
import instagram from '../../../assets/instagram.svg';
import { styled } from '@mui/material/styles';
import { Config } from 'src/config';
import { StyledComponent } from 'src/components/styled-components/styledComponent';
import { CommonHelper } from "src/utils/commonHelper";
import CustomDomainRedirectIframe from '../customDomainIframe';


const InstagramIntegration = (props) => {
    const [isAuthorizationModalOpen, setAuthorizationModal] = useState(true);
    const [isNoAccountForIntegrate, setNoAccountIntegrated] = useState(false);
    const [isInstaAccountModalOpen, setInstaAccountModal] = useState(false);
    const [accounts, setAccounts] = useState([]);
    const [selectedAccounts, setSelectedAccounts] = useState([]);
    const [isLoadSdk, setLoadSdk] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const { showToast } = useToaster();
    let isProcessing = false;
    const isCustomDomain = CommonHelper.CustomDomain();
    let intervalId = null;
    const messageListenerRef = useRef(null);

    useEffect(() => {
        intervalId = setInterval(() => {
            checkAuthoCodeReceivedFromFacebook();
        }, Config.Common.socialAccountsIntegrationInterval);
        return () => {
            clearInterval(intervalId);
            cleanupListener();
        };
    }, [])
    const instagramAccountSuccessCallback = (data, message) => {
        if (data.accounts && data.accounts.length > 0) {
            let isNoAccountForIntegrate = !data.accounts.some(a => !a.is_integrated);
            setNoAccountIntegrated(isNoAccountForIntegrate);
        }
        setAuthorizationModal(false);
        setInstaAccountModal(true);
        setAccounts(data.accounts);
    }
    const instagramAccountFailureCallback = (message) => {
        handleClose();
    }
    const cleanupListener = () => {
        if (messageListenerRef.current) {
            window.removeEventListener("message", messageListenerRef.current, false);
            messageListenerRef.current = null; // Reset the ref
        }
    };
    const getInstagramAccounts = (code) => {
        let instagramForm = {
            code,
        };
        let postProps = {
            url: integrateModuleURL.getInstagramAccountsFromToken,
            body: instagramForm,
            successCallback: instagramAccountSuccessCallback,
            failureCallback: instagramAccountFailureCallback
        }
        HttpServices.Post(postProps);
    };
    const instagramIntegrationSuccessCallback = (data, message) => {
        setLoading(false);
        showToast(message, 's');
        handleClose();
    }
    const instagramIntegrationFailureCallback = (message) => {
        setLoading(false);
        showToast(message, 'e');
        handleClose();
    }
    const handleSubmitInstagramAccounts = () => {
        setLoading(true);
        let instagramForm = {
            accounts: selectedAccounts,
        };
        let postProps = {
            url: integrateModuleURL.integrateInstagram,
            body: instagramForm,
            successCallback: instagramIntegrationSuccessCallback,
            failureCallback: instagramIntegrationFailureCallback
        }
        HttpServices.Post(postProps);
    };
    const handleAccounts = (accountId) => {
        let selectedAccountList = [...selectedAccounts];
        let index = selectedAccountList.findIndex((a) => a.id === accountId);
        if (index >= 0) selectedAccountList.splice(index, 1);
        else {
            let selectedPage = accounts.find((a) => a.id === accountId);
            selectedAccountList.push(selectedPage);
        }
        setSelectedAccounts(selectedAccountList);
    };

    const checkAuthoCodeReceivedFromFacebook = () => {
        if (isCustomDomain) {
            const handleMessage = (event) => {
                const { type, code } = event.data;
                if (type === "fb_auth_code" && code && !isProcessing) {
                    isProcessing = true
                    cleanupListener();
                    clearInterval(intervalId); // Stop polling
                    getInstagramAccounts(code); // Process the code
                }
            };
    
            if (!messageListenerRef.current) {
                window.addEventListener("message", handleMessage, false);
                messageListenerRef.current = handleMessage; // Save the reference
            }
        } else {
            const code = localStorage.getItem("fb_autho_code");
            const error = localStorage.getItem("fb_error");
            if (code) {
                localStorage.removeItem("fb_autho_code");
                clearInterval(intervalId);
                getInstagramAccounts(code);
            } else if (error) {
                localStorage.removeItem("fb_error");
                clearInterval(intervalId);
                showToast(error, 'e');
            }
        }
    };

    const handleConnectInstagram = () => {
        setLoadSdk(true);
    }

    const handleClose = () => {
        cleanupListener();
        props.handleClose();
    }

    const checkAccountIsIntegrated = (account) => {
        let isChecked = false;
        if (account.is_integrated)
            isChecked = true;
        else {
            let selectedAccountList = [...selectedAccounts];
            if (selectedAccountList && selectedAccountList.length > 0) {
                let index = selectedAccountList.findIndex(a => a.id === account.id);
                if (index >= 0)
                    isChecked = true;
            }
        }
        return isChecked;
    }
    const SmallAvatar = styled(Avatar)(({ theme }) => ({
        width: 20,
        height: 20,
        backgroundColor: '#fff',
    }));
    return (
        <>
            {isAuthorizationModalOpen &&
                <InstagramAuthorizationModal
                    handleConnectInstagram={handleConnectInstagram}
                    handleClose={handleClose}
                />}
            {isInstaAccountModalOpen ? (
                <Dialog onClose={handleClose} open={isInstaAccountModalOpen} fullWidth>
                    <DialogTitle>Instagram Accounts</DialogTitle>
                    <DialogContent dividers>
                        <div className="l2s-space-between">
                            <Box sx={{
                                minHeight: '220px',
                            }}>
                                {accounts &&
                                    accounts.length > 0 ?
                                    <>
                                        <List>
                                            {accounts.map((a) => {
                                                return (
                                                    <ListItem
                                                        secondaryAction={
                                                            <Checkbox
                                                                edge="end"
                                                                value={a.id}
                                                                checked={checkAccountIsIntegrated(a)}
                                                                onClick={isNoAccountForIntegrate ? null : () => { handleAccounts(a.id) }}
                                                                disabled={a.is_integrated}
                                                            />
                                                        }
                                                        disablePadding
                                                    >
                                                        <ListItemButton onClick={isNoAccountForIntegrate ? null : () => { handleAccounts(a.id) }} disabled={a.is_integrated}>
                                                            <ListItemAvatar>
                                                                <Badge
                                                                    overlap="circular"
                                                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                                    badgeContent={
                                                                        <SmallAvatar alt="" src={instagram} />
                                                                    }
                                                                >
                                                                    <Avatar
                                                                        src={a.profile_picture_url ? a.profile_picture_url : a.name || a.id}
                                                                        alt={a.name ? a.name : a.id}
                                                                        style={{ color: '#ffffff' }}
                                                                    />
                                                                </Badge>
                                                            </ListItemAvatar>
                                                            <ListItemText primary={a.name} />
                                                        </ListItemButton>
                                                    </ListItem>
                                                );
                                            })}
                                        </List>
                                        {isNoAccountForIntegrate && <Typography
                                            sx={{
                                                color: 'red',
                                                fontSize: '12px',
                                                marginTop: '10px'
                                            }}
                                        >
                                            All of your accounts are integrated
                                        </Typography>}
                                    </>
                                    :
                                    <Typography sx={{
                                        color: 'red',
                                        margin: '10px'
                                    }}>
                                        There is no Facebook pages are connected to your instagram account
                                    </Typography>
                                }
                            </Box>
                            {isCustomDomain && <CustomDomainRedirectIframe serviceName="instagram"/> }
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button color='error' variant="outlined" onClick={handleClose}>
                            Cancel
                        </Button>
                        {accounts && accounts.length > 0 && !isNoAccountForIntegrate &&
                            <LoadingButton variant="contained" disabled={selectedAccounts.length === 0} loading={isLoading} autoFocus onClick={handleSubmitInstagramAccounts}>
                                Submit
                            </LoadingButton>}
                    </DialogActions>
                </Dialog >
            ) : null}
            {isLoadSdk && <LoadFacebookSdk />}
        </>
    );
};
export default InstagramIntegration;
