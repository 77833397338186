import { Card, FormControl, InputLabel, MenuItem, Select, Typography, Box, useTheme, Stack, useMediaQuery } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useState } from 'react';
import { CommonHelper } from 'src/utils/commonHelper';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const ScheduledPostFilter = ({ handleApplyFilter, fromDate, toDate }) => {
    const [filterTypes, setFilterTypes] = useState(["All", "Custom"]);
    const [selectedFilterType, setFilterType] = useState("All");
    const [startDate, setStartDate] = useState(fromDate);
    const [endDate, setEndDate] = useState(toDate);
    const [customStartSelected, setCustomStartSelected] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleFilterChange = (event) => {
        let value = event.target.value;
        setFilterType(value);
        setDateBasedOnFilterType(value);
    }

    const setDateBasedOnFilterType = (type) => {
        if (type === "All") {
            // Reset to original range
            setErrorMessage("");
            let currentDate = new Date();
            let tDate = currentDate.setDate(currentDate.getDate() + 365);
            currentDate.setHours(0, 0, 0, 0);
            tDate = new Date(currentDate);
            setStartDate(new Date());
            setEndDate(tDate);
            handleApplyFilter(new Date(), tDate); // Apply "All" filter
        } else if (type === "Custom") {
            // Clear custom range
            setStartDate(null);
            setEndDate(null);
            setCustomStartSelected(false);
            setErrorMessage("");
        }
    };

    const handleChangeDate = (date, type) => {
        setErrorMessage(""); // Clear any previous error messages

        if (type === "fromdate") {
            setStartDate(date);
            setEndDate(null); // Reset end date when start date changes
            setCustomStartSelected(true);
        } else if (type === "todate") {
            setEndDate(date);
        }

        // Check if dates are valid and apply filter
        if (type === "todate" && checkForDateValidation(startDate, date)) {
            handleApplyFilter(startDate, date);
        }
    };

    const checkForDateValidation = (startDate, endDate) => {
        if (!startDate || !endDate) {
            setErrorMessage("Please select both start and end dates.");
            return false;
        }
        if (new Date(startDate) > new Date(endDate)) {
            setErrorMessage("Start date cannot be after the end date.");
            return false;
        }
        return true;
    };


    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                alignItems: 'center',
                justifyContent: 'center',
                gap: isMobile ? '0.2rem' : '1rem'
            }}
        >
            <FormControl sx={{ minWidth: isMobile ? 310 : 150 }}>
                <InputLabel id="date_filter">Choose Date Filter</InputLabel>
                <Select
                    value={selectedFilterType}
                    label="Choose Date Filter"
                    onChange={handleFilterChange}
                >
                    {filterTypes.map((filter) => (
                        <MenuItem value={filter} key={filter}>
                            {filter}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <Stack
                direction={isMobile ? 'column' : 'row'}
                sx={{ alignItems: 'center', gap: '1rem' }}
            >
                {selectedFilterType === 'Custom' && (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="Start Date"
                            value={startDate}
                            onChange={(newValue) => handleChangeDate(newValue, 'fromdate')}
                            disablePast
                        />
                        <DatePicker
                            label="End Date"
                            value={endDate}
                            onChange={(newValue) => handleChangeDate(newValue, 'todate')}
                            minDate={startDate || null}
                            disabled={!customStartSelected}
                        />
                    </LocalizationProvider>
                )}
            </Stack>

            {startDate && endDate && (
                <Stack
                    direction={isMobile ? 'column' : 'row'}
                    sx={{ justifyContent: 'center', alignItems: 'center', gap: 1 }}
                >
                    {!isMobile && <CalendarMonthIcon sx={{ color: 'primary.main' }} />}
                    <Typography>
                        Filter applied from{' '}
                        <span
                            style={{
                                cursor: 'pointer',
                                textDecoration: 'underline',
                                color: theme.palette.primary.main,
                                marginLeft: '4px',
                                fontWeight: 600
                            }}
                        >
                            {CommonHelper.formatDateAndTime(startDate, 'date')}
                        </span>{' '}
                        to
                        <span
                            style={{
                                cursor: 'pointer',
                                textDecoration: 'underline',
                                color: theme.palette.primary.main,
                                marginLeft: '4px',
                                fontWeight: 600
                            }}
                        >
                            {CommonHelper.formatDateAndTime(endDate, 'date')}
                        </span>
                    </Typography>
                </Stack>
            )
            }
        </Box >
    );
}

export default ScheduledPostFilter;
