import { CommonHelper } from 'src/utils/commonHelper';



const requireJson = require.context('../../utils/demoUtil', true, /\.json$/);

const LoadJsonFromFile = (filename) => {
  try {
    const data = requireJson(`./${filename}`);
    return data;
  } catch (error) {
    console.error('Error loading JSON file:', error);
    throw new Error('File not found');
  }
};

const GetAPIData = (url, activeBrandId, body) => {

  var isDemoAccount = IsDemoAccount()
  if (!isDemoAccount) {
    return "no preloaded data";
  }
  const urlObj = new URL(url);
  const path = urlObj.pathname;

  var demoJsonfilename = ""
  if (activeBrandId == GetActiveBrandId()) {
    switch (path) {
      case '/api/profile-master-services/private/initial-load':
        demoJsonfilename = "profile-master.json";
        break;
      case '/api/integration-services/connected-profiles':
        demoJsonfilename = "connectedprofiles/connectedprofiles.json";
        break;
      case '/api/integration-services/get-dashboard-details':
        demoJsonfilename = "dashboard/dashboard.json";
        break;
      case '/api/notification-services/fetch-notification':
        demoJsonfilename = "notification/notification.json";
        break;
      case '/api/facebook-services/get-published-posts':
        demoJsonfilename = "publishedpost/facebook/facebook-publishedpost.json";
        break;
      case '/api/facebook-services/get-post-details':
        demoJsonfilename = "publishedpost/facebook/facebook-getpostdetails.json";
        break;
      case '/api/instagram-services/get-published-posts':
        demoJsonfilename = "publishedpost/instagram/instagram-publishedpost.json";
        break;
      case '/api/instagram-services/get-post-details':
        demoJsonfilename = "publishedpost/instagram/instagram-getpostdetails.json";
        break;
      case '/api/youtube-services/get-published-posts':
        demoJsonfilename = "publishedpost/youtube/youtube-publishedpost.json";
        break;
      case '/api/youtube-services/get-post-details':
        demoJsonfilename = "publishedpost/youtube/youtube-getpostdetails.json";
        break;
      case '/api/linkedin-services/page/get-published-posts':
        demoJsonfilename = "publishedpost/linkedin/linkedin-publishedpost.json";
        break;
      case '/api/linkedin-services/page/get-post-details':
        demoJsonfilename = "publishedpost/linkedin/linkedin-getpostdetails.json";
        break;
      case '/api/pinterest-services/get-published-posts':
        demoJsonfilename = "publishedpost/pinterest/pinterest-publishedpost.json";
        break;
      case '/api/pinterest-services/get-post-details':
        demoJsonfilename = "publishedpost/pinterest/pinterest-getpostdetails.json";
        break;
      case '/api/tiktok-services/get-published-posts':
        demoJsonfilename = "publishedpost/tiktok/tiktok-publishedpost.json";
        break;
      case '/api/tiktok-services/get-post-details':
        demoJsonfilename = "publishedpost/tiktok/tiktok-getpostdetails.json";
        break;
      case '/api/gbusiness-services/get-published-posts':
        demoJsonfilename = "publishedpost/gbusiness/gbusiness-publishedpost.json";
        break;
      case '/api/gbusiness-services/get-post-details':
        demoJsonfilename = "publishedpost/gbusiness/gbusiness-getpostdetails.json";
        break;
      case '/api/facebook-services/get-all-messages':
        demoJsonfilename = "socialinbox/facebook-inbox/facebook-messages.json";
        break;
      case '/api/facebook-services/get-conversation-message':
        demoJsonfilename = "socialinbox/facebook-inbox/facebook-conversationmessage.json";
        break;
      case '/api/instagram-services/get-all-messages':
        demoJsonfilename = "socialinbox/instagram-inbox/instagram-messages.json";
        break;
      case '/api/instagram-services/get-conversation-message':
        demoJsonfilename = "socialinbox/instagram-inbox/instagram-conversationmessage.json";
        break;
      case '/api/gbusiness-services/get-all-review':
        demoJsonfilename = "socialinbox/gbusiness-inbox/gbusiness-review.json";
        break;
      case '/api/gbusiness-services/get-all-questions':
        demoJsonfilename = "socialinbox/gbusiness-inbox/gbusiness-questions.json";
        break;
      case '/api/integration-services/get-all-scheduled-reports':
        demoJsonfilename = "scheduledreports/scheduledreport.json";
        break;
      case '/api/integration-services/get-share-approval-posts':
        demoJsonfilename = "scheduled-post/get-share-approval-posts.json";
        break;
      case '/api/integration-services/approved-calendar-events':
        demoJsonfilename = "scheduled-post/approved-calender.json";
        break;
      case '/api/integration-services/failed-calendar-events':
        demoJsonfilename = "scheduled-post/failed-calender.json";
        break;
      case '/api/integration-services/scheduled-event-info':
        demoJsonfilename = "scheduled-post/scheduled-event-info.json";
        break;
      case '/api/integration-services/calendar-events':
        demoJsonfilename = "calenderevents/calender-events.json";
        break;
      case '/api/integration-services/get-unapproved-post':
        demoJsonfilename = "approval/unapproval.json";
        break;
      case '/api/integration-services/get-rejected-post':
        demoJsonfilename = "approval/rejected.json";
        break;
      case '/api/integration-services/get-elapsed-post':
        demoJsonfilename = "approval/elapsed.json";
        break;
      case '/api/integration-services/get-saved-draft':
        demoJsonfilename = "draft/saveddraft.json";
        break;
      case '/api/image-server/private/fetch-user-media':
        demoJsonfilename = "media/media.json";
        break;
      case '/api/integration-services/get-all-reports':
        const params = new URLSearchParams(urlObj.search);
        const serviceName = params.get("service_name");
        switch (serviceName) {
          case 'facebook':
            demoJsonfilename = "reports/get-all-reports-facebook.json";
            break;
          case 'instagram':
            demoJsonfilename = "reports/get-all-reports-instagram.json";
            break;
          case 'youtube':
            demoJsonfilename = "reports/get-all-reports-youtube.json";
            break;
          default:
            demoJsonfilename = "reports/get-all-reports-facebook.json";
            break;
        }
        break;
      case '/api/facebook-services/get-predefined-report':
        switch (body.report_id) {
          case 124:
            demoJsonfilename = "reports/facebook-predefined-report-aduience-summary.json";
            break;
          case 125:
            demoJsonfilename = "reports/facebook-predefined-report-engagement-summary.json";
            break;
        }
        break;
      case '/api/instagram-services/get-predefined-report':
        switch (body.report_id) {
          case 129:
            demoJsonfilename = "reports/instagram-predefined-report-aduience-summary.json";
            break;
          case 130:
            demoJsonfilename = "reports/instagram-predefined-report-engagement-summary.json";
            break;
        }
        break;
      case '/api/youtube-services/get-predefined-report':
        switch (body.report_id) {
          case 135:
            demoJsonfilename = "reports/youtube-predefined-report-aduience-summary.json";
            break;
          case 136:
            demoJsonfilename = "reports/youtube-predefined-report-engagement-summary.json";
            break;
        }
        break;
      case '/api/profile-master-services/private/fetch-user-details':
        demoJsonfilename = "profile/fetch-user-details.json";
        break;
      case '/api/profile-master-services/private/fetch-users-brand':
        demoJsonfilename = "profile/fetch-users-brand.json";
        break;
      case '/api/profile-master-services/private/fetch-brand-info':
        demoJsonfilename = "brand/brand-info.json";
        break;
      case '/api/profile-master-services/private/fetch-all-roles':
        demoJsonfilename = "brand/brand-all-roles.json";
        break;
      case '/api/profile-master-services/private/fetch-brand-users':
        demoJsonfilename = "brand/brand-users.json";
        break;
      case '/api/profile-master-services/private/get-all-pending-invites':
        demoJsonfilename = "brand/pending-invites.json";
        break;
      case '/api/profile-master-services/private/fetch-org-admins':
        demoJsonfilename = "organization/org-admins.json";
        break;
      case '/api/profile-master-services/private/fetch-org-users':
        demoJsonfilename = "organization/org-users.json";
        break;
      case '/api/profile-master-services/private/fetch-org-info':
        demoJsonfilename = "organization/org-info.json";
        break;
      case '/api/payment-services/get-current-plan-details':
        demoJsonfilename = "payment/current-plan-details.json";
        break;
      case '/api/payment-services/get-all-plans':
        demoJsonfilename = "payment/get-all-plans.json";
        break;
      case '/api/profile-master-services/private/get-branding-details':
        demoJsonfilename = "brand/branding-details.json";
        break;
      case '/api/notification-services/get-notification-settings':
        demoJsonfilename = "notification/notification-settings.json";
        break;
      case '/api/integration-services/share-approval-posts':
        demoJsonfilename = "scheduled-post/share-approval-posts.json";
        break;
      case '/api/pinterest-services/fetch-all-boards':
        demoJsonfilename = "pinterest/fetch-all-boards.json";
        break;
      case '/api/gbusiness-services/get-phone-number':
        demoJsonfilename = "gbusiness/get-phone-number.json";
        break;
      case '/api/integration-services/publish-new-post':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/profile-master-services/private/check-ai-enabled':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/profile-master-services/private/update-brand-details':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/integration-services/email-export-report':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/integration-services/create-scheduled-report':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/integration-services/edit-scheduled-report':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/integration-services/delete-scheduled-report':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/facebook-services/add-message':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/facebook-services/add-like':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/facebook-services/remove-like':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/facebook-services/add-comment':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/facebook-services/delete':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/instagram-services/add-comment':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/facebook-services/get-more-replies':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/instagram-services/add-reply-comment':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/instagram-services/delete':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/youtube-services/add-remove-like':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/youtube-services/add-comment':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/youtube-services/add-reply-comment':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/youtube-services/delete':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/linkedin-services/page/add-post-like':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/linkedin-services/page/add-comment':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/linkedin-services/page/add-comment-like':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/linkedin-services/page/add-reply-comment':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/linkedin-services/page/delete-comment':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/instagram-services/add-message':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/gbusiness-services/add-review':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/gbusiness-services/delete-review':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/integration-services/disable-scheduled-event':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/integration-services/add-comment-on-scheduled-event':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/integration-services/reschedule-new-post':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/image-server/fetch-media-metadata':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/integration-services/save-post-as-draft':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/integration-services/delete-scheduled-event':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/integration-services/unapproved-post':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/integration-services/remove-share-approval-posts':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/integration-services/retry-post':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/integration-services/approve-post':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/integration-services/reject-post':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/integration-services/delete-draft':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/image-server/upload-media':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/image-server/fetch-private-media-metadata':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/facebook-services/get-groups-and-pages':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/profile-master-services/public/get-domain-details':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/facebook-services/integrate-pages-and-groups':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/instagram-services/get-instagram-account-from-token':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/instagram-services/integrate-instagram':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/twitter-services/integrate':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/twitter-services/save-access-token':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/youtube-services/save-access-token':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/linkedin-services/profile/save-access-token':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/linkedin-services/page/get-linked-pages':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/pinterest-services/save-access-token':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/gbusiness-services/save-access-token':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/tiktok-services/save-access-token':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/integration-services/disable-accounts':
        demoJsonfilename = "postmethod/postmethod.json";
        break
      case '/api/facebook-services/update-account':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/facebook-services/re-authenticate-account':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/integration-services/disintegrate-accounts':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/instagram-services/update-account':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/instagram-services/re-authenticate-account':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/youtube-services/re-authenticate-account':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/youtube-services/update-account':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/gbusiness-services/re-authenticate-account':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/gbusiness-services/update-account':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/twitter-services/re-authenticate-account':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/twitter-services/update-account':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/linkedin-services/re-authenticate-account':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case 'api/linkedin-services/update-account':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/pinterest-services/re-authenticate-account':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/pinterest-services/update-account':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/tiktok-services/re-authenticate-account':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/tiktok-services/update-account':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/profile-master-services/private/new-role-init':
        demoJsonfilename = "connectedprofiles/new-role-init.json";
        break;
      case '/api/profile-master-services/private/create-new-role':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/profile-master-services/private/send-invite-create-account':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/profile-master-services/private/update-user-role':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/profile-master-services/private/remove-user-from-org':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/profile-master-services/private/remove-user-from-brand':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/profile-master-services/private/new-user-init':
        demoJsonfilename = "connectedprofiles/new-user-init.json";
        break;
      case '/api/profile-master-services/private/disable-user':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/profile-master-services/private/disable-brand':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/profile-master-services/private/delete-brand':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/profile-master-services/private/create-new-brand':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/profile-master-services/private/update-org-details':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/payment-services/change-plan':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/payment-services/change-plan-payment':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/profile-master-services/private/update-branding':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/profile-master-services/private/delete-cname':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/notification-services/save-notification-settings':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/profile-master-services/private/leave-from-org':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/profile-master-services/private/delete-brand':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/profile-master-services/private/leave-from-brand':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/profile-master-services/private/delete-me':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/profile-master-services/private/delete-org':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      default:
        demoJsonfilename = ""
        break;
    }
  }
  else {
    switch (path) {
      case '/api/profile-master-services/private/initial-load':
        demoJsonfilename = "profile-master.json";
        break;
      case '/api/integration-services/connected-profiles':
        demoJsonfilename = "demoutilsbrand/connectedprofiles/connectedprofiles.json";
        break;
      case '/api/integration-services/get-dashboard-details':
        demoJsonfilename = "demoutilsbrand/dashboard/dashboard.json";
        break;
      case '/api/notification-services/fetch-notification':
        demoJsonfilename = "demoutilsbrand/notification/notification.json";
        break;
      case '/api/facebook-services/get-published-posts':
        demoJsonfilename = "demoutilsbrand/publishedpost/facebook/facebook-publishedpost.json";
        break;
      case '/api/facebook-services/get-post-details':
        demoJsonfilename = "demoutilsbrand/publishedpost/facebook/facebook-getpostdetails.json";
        break;
      case '/api/instagram-services/get-published-posts':
        demoJsonfilename = "demoutilsbrand/publishedpost/instagram/instagram-publishedpost.json";
        break;
      case '/api/instagram-services/get-post-details':
        demoJsonfilename = "demoutilsbrand/publishedpost/instagram/instagram-getpostdetails.json";
        break;
      case '/api/youtube-services/get-published-posts':
        demoJsonfilename = "demoutilsbrand/publishedpost/youtube/youtube-publishedpost.json";
        break;
      case '/api/youtube-services/get-post-details':
        demoJsonfilename = "demoutilsbrand/publishedpost/youtube/youtube-getpostdetails.json";
        break;
      case '/api/linkedin-services/page/get-published-posts':
        demoJsonfilename = "demoutilsbrand/publishedpost/linkedin/linkedin-publishedpost.json";
        break;
      case '/api/linkedin-services/page/get-post-details':
        demoJsonfilename = "demoutilsbrand/publishedpost/linkedin/linkedin-getpostdetails.json";
        break;
      case '/api/pinterest-services/get-published-posts':
        demoJsonfilename = "demoutilsbrand/publishedpost/pinterest/pinterest-publishedpost.json";
        break;
      case '/api/pinterest-services/get-post-details':
        demoJsonfilename = "demoutilsbrand/publishedpost/pinterest/pinterest-getpostdetails.json";
        break;
      case '/api/tiktok-services/get-published-posts':
        demoJsonfilename = "demoutilsbrand/publishedpost/tiktok/tiktok-publishedpost.json";
        break;
      case '/api/tiktok-services/get-post-details':
        demoJsonfilename = "demoutilsbrand/publishedpost/tiktok/tiktok-getpostdetails.json";
        break;
      case '/api/gbusiness-services/get-published-posts':
        demoJsonfilename = "demoutilsbrand/publishedpost/gbusiness/gbusiness-publishedpost.json";
        break;
      case '/api/gbusiness-services/get-post-details':
        demoJsonfilename = "demoutilsbrand/publishedpost/gbusiness/gbusiness-getpostdetails.json";
        break;
      case '/api/facebook-services/get-all-messages':
        demoJsonfilename = "demoutilsbrand/socialinbox/facebook-inbox/facebook-messages.json";
        break;
      case '/api/facebook-services/get-conversation-message':
        demoJsonfilename = "demoutilsbrand/socialinbox/facebook-inbox/facebook-conversationmessage.json";
        break;
      case '/api/instagram-services/get-all-messages':
        demoJsonfilename = "demoutilsbrand/socialinbox/instagram-inbox/instagram-messages.json";
        break;
      case '/api/instagram-services/get-conversation-message':
        demoJsonfilename = "demoutilsbrand/socialinbox/instagram-inbox/instagram-conversationmessage.json";
        break;
      case '/api/gbusiness-services/get-all-review':
        demoJsonfilename = "demoutilsbrand/socialinbox/gbusiness-inbox/gbusiness-review.json";
        break;
      case '/api/gbusiness-services/get-all-questions':
        demoJsonfilename = "demoutilsbrand/socialinbox/gbusiness-inbox/gbusiness-questions.json";
        break;
      case '/api/integration-services/get-all-scheduled-reports':
        demoJsonfilename = "demoutilsbrand/scheduledreports/scheduledreport.json";
        break;
      case '/api/integration-services/get-share-approval-posts':
        demoJsonfilename = "demoutilsbrand/scheduled-post/get-share-approval-posts.json";
        break;
      case '/api/integration-services/approved-calendar-events':
        demoJsonfilename = "demoutilsbrand/scheduled-post/approved-calender.json";
        break;
      case '/api/integration-services/failed-calendar-events':
        demoJsonfilename = "demoutilsbrand/scheduled-post/failed-calender.json";
        break;
      case '/api/integration-services/scheduled-event-info':
        demoJsonfilename = "demoutilsbrand/scheduled-post/scheduled-event-info.json";
        break;
      case '/api/integration-services/calendar-events':
        demoJsonfilename = "demoutilsbrand/calenderevents/calender-events.json";
        break;
      case '/api/integration-services/get-unapproved-post':
        demoJsonfilename = "demoutilsbrand/approval/unapproval.json";
        break;
      case '/api/integration-services/get-rejected-post':
        demoJsonfilename = "demoutilsbrand/approval/rejected.json";
        break;
      case '/api/integration-services/get-elapsed-post':
        demoJsonfilename = "demoutilsbrand/approval/elapsed.json";
        break;
      case '/api/integration-services/get-saved-draft':
        demoJsonfilename = "demoutilsbrand/draft/saveddraft.json";
        break;
      case '/api/image-server/private/fetch-user-media':
        demoJsonfilename = "media/media.json";
        break;
      case '/api/integration-services/get-all-reports':
        const params = new URLSearchParams(urlObj.search);
        const serviceName = params.get("service_name");
        switch (serviceName) {
          case 'facebook':
            demoJsonfilename = "demoutilsbrand/reports/get-all-reports-facebook.json";
            break;
          case 'instagram':
            demoJsonfilename = "demoutilsbrand/reports/get-all-reports-instagram.json";
            break;
          case 'youtube':
            demoJsonfilename = "demoutilsbrand/reports/get-all-reports-youtube.json";
            break;
          default:
            demoJsonfilename = "demoutilsbrand/reports/get-all-reports-facebook.json";
            break;
        }
        break;
      case '/api/facebook-services/get-predefined-report':
        switch (body.report_id) {
          case 124:
            demoJsonfilename = "demoutilsbrand/reports/facebook-predefined-report-aduience-summary.json";
            break;
          case 125:
            demoJsonfilename = "demoutilsbrand/reports/facebook-predefined-report-engagement-summary.json";
            break;
        }
        break;
      case '/api/instagram-services/get-predefined-report':
        switch (body.report_id) {
          case 129:
            demoJsonfilename = "demoutilsbrand/reports/instagram-predefined-report-aduience-summary.json";
            break;
          case 130:
            demoJsonfilename = "demoutilsbrand/reports/instagram-predefined-report-engagement-summary.json";
            break;
        }
        break;
      case '/api/youtube-services/get-predefined-report':
        switch (body.report_id) {
          case 135:
            demoJsonfilename = "demoutilsbrand/reports/youtube-predefined-report-aduience-summary.json";
            break;
          case 136:
            demoJsonfilename = "demoutilsbrand/reports/youtube-predefined-report-engagement-summary.json";
            break;
        }
        break;
      case '/api/profile-master-services/private/fetch-user-details':
        demoJsonfilename = "demoutilsbrand/profile/fetch-user-details.json";
        break;
      case '/api/profile-master-services/private/fetch-users-brand':
        demoJsonfilename = "demoutilsbrand/profile/fetch-users-brand.json";
        break;
      case '/api/profile-master-services/private/fetch-brand-info':
        demoJsonfilename = "demoutilsbrand/brand/brand-info.json";
        break;
      case '/api/profile-master-services/private/fetch-all-roles':
        demoJsonfilename = "demoutilsbrand/brand/brand-all-roles.json";
        break;
      case '/api/profile-master-services/private/fetch-brand-users':
        demoJsonfilename = "demoutilsbrand/brand/brand-users.json";
        break;
      case '/api/profile-master-services/private/get-all-pending-invites':
        demoJsonfilename = "demoutilsbrand/brand/pending-invites.json";
        break;
      case '/api/profile-master-services/private/fetch-org-admins':
        demoJsonfilename = "demoutilsbrand/organization/org-admins.json";
        break;
      case '/api/profile-master-services/private/fetch-org-users':
        demoJsonfilename = "demoutilsbrand/organization/org-users.json";
        break;
      case '/api/profile-master-services/private/fetch-org-info':
        demoJsonfilename = "demoutilsbrand/organization/org-info.json";
        break;
      case '/api/payment-services/get-current-plan-details':
        demoJsonfilename = "demoutilsbrand/payment/current-plan-details.json";
        break;
      case '/api/payment-services/get-all-plans':
        demoJsonfilename = "demoutilsbrand/payment/get-all-plans.json";
        break;
      case '/api/profile-master-services/private/get-branding-details':
        demoJsonfilename = "demoutilsbrand/brand/branding-details.json";
        break;
      case '/api/notification-services/get-notification-settings':
        demoJsonfilename = "demoutilsbrand/notification/notification-settings.json";
        break;
      case '/api/integration-services/share-approval-posts':
        demoJsonfilename = "scheduled-post/share-approval-posts.json";
        break;
      case '/api/pinterest-services/fetch-all-boards':
        demoJsonfilename = "pinterest/fetch-all-boards.json";
        break;
      case '/api/gbusiness-services/get-phone-number':
        demoJsonfilename = "gbusiness/get-phone-number.json";
        break;
      case '/api/integration-services/publish-new-post':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/profile-master-services/private/check-ai-enabled':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/profile-master-services/private/update-brand-details':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/integration-services/email-export-report':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/integration-services/create-scheduled-report':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/integration-services/edit-scheduled-report':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/integration-services/delete-scheduled-report':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/facebook-services/add-message':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/facebook-services/add-like':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/facebook-services/remove-like':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/facebook-services/add-comment':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/facebook-services/delete':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/instagram-services/add-comment':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/facebook-services/get-more-replies':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/instagram-services/add-reply-comment':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/instagram-services/delete':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/youtube-services/add-remove-like':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/youtube-services/add-comment':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/youtube-services/add-reply-comment':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/youtube-services/delete':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/linkedin-services/page/add-post-like':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/linkedin-services/page/add-comment':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/linkedin-services/page/add-comment-like':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/linkedin-services/page/add-reply-comment':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/linkedin-services/page/delete-comment':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/instagram-services/add-message':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/gbusiness-services/add-review':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/gbusiness-services/delete-review':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/integration-services/disable-scheduled-event':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/integration-services/add-comment-on-scheduled-event':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/integration-services/reschedule-new-post':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/image-server/fetch-media-metadata':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/integration-services/save-post-as-draft':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/integration-services/delete-scheduled-event':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/integration-services/unapproved-post':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/integration-services/remove-share-approval-posts':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/integration-services/retry-post':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/integration-services/approve-post':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/integration-services/reject-post':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/integration-services/delete-draft':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/image-server/upload-media':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/image-server/fetch-private-media-metadata':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/facebook-services/get-groups-and-pages':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/profile-master-services/public/get-domain-details':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/facebook-services/integrate-pages-and-groups':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/instagram-services/get-instagram-account-from-token':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/instagram-services/integrate-instagram':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/twitter-services/integrate':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/twitter-services/save-access-token':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/youtube-services/save-access-token':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/linkedin-services/profile/save-access-token':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/linkedin-services/page/get-linked-pages':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/pinterest-services/save-access-token':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/gbusiness-services/save-access-token':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/tiktok-services/save-access-token':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/integration-services/disable-accounts':
        demoJsonfilename = "postmethod/postmethod.json";
        break
      case '/api/facebook-services/update-account':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/facebook-services/re-authenticate-account':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/integration-services/disintegrate-accounts':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/instagram-services/update-account':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/instagram-services/re-authenticate-account':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/youtube-services/re-authenticate-account':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/youtube-services/update-account':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/gbusiness-services/re-authenticate-account':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/gbusiness-services/update-account':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/twitter-services/re-authenticate-account':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/twitter-services/update-account':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/linkedin-services/re-authenticate-account':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case 'api/linkedin-services/update-account':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/pinterest-services/re-authenticate-account':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/pinterest-services/update-account':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/tiktok-services/re-authenticate-account':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/tiktok-services/update-account':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/profile-master-services/private/new-role-init':
        demoJsonfilename = "demoutilsbrand/connectedprofiles/new-role-init.json";
        break;
      case '/api/profile-master-services/private/create-new-role':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/profile-master-services/private/send-invite-create-account':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/profile-master-services/private/update-user-role':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/profile-master-services/private/remove-user-from-org':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/profile-master-services/private/remove-user-from-brand':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/profile-master-services/private/new-user-init':
        demoJsonfilename = "demoutilsbrand/connectedprofiles/new-user-init.json";
        break;
      case '/api/profile-master-services/private/disable-user':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/profile-master-services/private/disable-brand':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/profile-master-services/private/delete-brand':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/profile-master-services/private/create-new-brand':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/profile-master-services/private/update-org-details':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/payment-services/change-plan':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/payment-services/change-plan-payment':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/profile-master-services/private/update-branding':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/profile-master-services/private/delete-cname':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/notification-services/save-notification-settings':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/profile-master-services/private/leave-from-org':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/profile-master-services/private/delete-brand':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/profile-master-services/private/leave-from-brand':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/profile-master-services/private/delete-me':
        demoJsonfilename = "postmethod/postmethod.json";
        break;
      case '/api/profile-master-services/private/delete-org':
        demoJsonfilename = "postmethod/postmethod.json";
        break;

      default:
        demoJsonfilename = ""
        break;
    }
  }
  if (demoJsonfilename == "") {
    return "no preloaded data";
  }
  var jsonresp = LoadJsonFromFile(demoJsonfilename)
  if (demoJsonfilename == "profile-master.json"){
    jsonresp.data.org_id = parseInt(GetDemoAccount(), 10)
  }
  return jsonresp;
};

  const IsDemoAccount = () => {
    var orgId = CommonHelper.GetBrandInfoFromLocalStorage("org_id")
    if (orgId == GetDemoAccount()) {
      return true;
    }
    return false;
  }

  const GetDemoAccount = () => {
    return "347"
  }

  const GetActiveBrandId = () => {
    return "52"
  }
  

export const DemoHelper = {
  GetAPIData,
  IsDemoAccount,
  LoadJsonFromFile,
  GetDemoAccount,
}