import { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogActions, DialogContent, Typography, CardContent, ListItemIcon, Paper, TextField, FormHelperText } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import HttpServices from 'src/services/httpService';
import { integrateModuleURL } from 'src/services/urlService';

const PinterestCreateBoard = ({ handleClose, profileId, NewBoardCreationCallback }) => {
    const [isLoadingOn, setLoadingOn] = useState(false);
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [nameError, setNameError] = useState('');
    const [descError, setDescError] = useState('');

    const successCallback = (data, message) => {
        setLoadingOn(false);
        NewBoardCreationCallback(data);
        handleClose();
    };

    const failureCallback = (error) => {
        setLoadingOn(false);
    };

    const createBoard = () => {
        setLoadingOn(true);
        const formBody = {
            connected_profile_id: profileId,
            name: name,
            description: description,
        };
        const postProps = {
            url: integrateModuleURL.CreateBoard,
            body: formBody,
            successCallback,
            failureCallback,
        };
        HttpServices.Post(postProps);
    };

    const handleNameChange = (e) => {
        const value = e.target.value;
        if (value.length <= 50) {
            setName(value);
            setNameError('');
        } else {
            setNameError('Name cannot exceed 50 characters');
        }
    };

    const handleDescriptionChange = (e) => {
        const value = e.target.value;
        if (value.length <= 50) {
            setDescription(value);
            setDescError('');
        } else {
            setDescError('Description cannot exceed 50 characters');
        }
    };

    return (
        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open>
            <DialogTitle id="customized-dialog-title">
                Create Pinterest Board
            </DialogTitle>
            <DialogContent dividers>
                <Typography gutterBottom>
                    Please provide the board details:
                </Typography>
                <TextField
                    placeholder="Board Name"
                    value={name}
                    onChange={handleNameChange}
                    style={{ width: '100%', padding: '8px', marginBottom: '10px' }}
                    helperText={nameError}
                    error={!!nameError}
                />
                <TextField
                    placeholder="Description"
                    value={description}
                    onChange={handleDescriptionChange}
                    style={{ width: '100%', padding: '8px' }}
                    helperText={descError}
                    error={!!descError}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color="error" onClick={handleClose}>
                    Close
                </Button>
                <LoadingButton
                    variant="contained"
                    loading={isLoadingOn}
                    onClick={createBoard}
                    disabled={!name}
                >
                    Create Board
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default PinterestCreateBoard;
