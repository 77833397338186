import { Table, TableContainer } from '@mui/material';
import { useState } from 'react';
import Scrollbar from 'src/components/scrollbar/Scrollbar';
import DynamicHeightComponent from 'src/utils/dynamicSize.component';
import ScheduledPostGridBody from './allPostGridBody.component';
import ScheduledPostGridHead from './allPostGridHead.component';
import ScheduledPostGridPagination from './allPostGridPagination.component';

const ScheduledPostGrid = ({ scheduledPostList, order, orderBy, handleReCallScheduledPost }) => {

  //Table Variables
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // const [height, setHeight] = useState(window.innerHeight - 110);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    let orderStr = isAsc ? 'desc' : 'asc'
    handleReCallScheduledPost(property, orderStr)
  };

  const handleRecallScheduledPostWithSort = () => {
    handleReCallScheduledPost(orderBy, order)
  };

  return (
    <>
      <DynamicHeightComponent>
        <Scrollbar>
          <TableContainer
            sx={{
              maxHeight: 'calc(100vh - 305px)',
              minHeight: 'calc(100vh - 305px)',
            }}
          >
            <Table stickyHeader size='small'>
              <ScheduledPostGridHead
                scheduledPostList={scheduledPostList}
                order={order}
                orderBy={orderBy}
                handleRequestSort={handleRequestSort}>
              </ScheduledPostGridHead>
              <ScheduledPostGridBody
                scheduledPostList={scheduledPostList}
                page={page}
                rowsPerPage={rowsPerPage}
                handleReCallScheduledPost={handleRecallScheduledPostWithSort}>
              </ScheduledPostGridBody>
            </Table>
          </TableContainer>
          <ScheduledPostGridPagination
            scheduledPostList={scheduledPostList}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          >
          </ScheduledPostGridPagination>
        </Scrollbar>
      </DynamicHeightComponent>
    </>
  );
};
export default ScheduledPostGrid;
