import axios from 'axios';
import { Constants } from 'src/constants/stringConstants';
import { CommonHelper } from 'src/utils/commonHelper';
import { DemoHelper } from 'src/utils/demoUtil/demoUtil';
import { LocalStorage } from 'src/utils/storage/local-storage';
import { demoModuleUrl, imageModeuleUrl } from './urlService';
import { SessionStorage } from 'src/utils/storage/session-storage';

const getToken = () => {
  let cookie = LocalStorage.getItem('token');
  return cookie ? cookie : '';
};

const GetRequestHeader = (contentType = '', isNeedAutho) => {
  const activeBrandId = CommonHelper.GetCurrentBrandId();
  if (!activeBrandId) {
    let brandDetails = CommonHelper.GetBrandInfoFromLocalStorage('brand_details');
    if (brandDetails && brandDetails.length > 0) {
      SessionStorage.setItem('current_brand_id', JSON.stringify(brandDetails[0].brand_id));
      window.location.reload();
    }
  }
  const headers = {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*',
    'Content-type': contentType === 'form' ? 'multipart/form-data' : 'application/json',
    BrandId: isNeedAutho ? activeBrandId : '',
    Authorization: isNeedAutho ? getToken() : '',
  };
  return headers;
};

const GetDemoJson = (props) => {
  let { url,body, responseType, isNeedAutho = true, successCallback, failureCallback } = props;
  let config = { headers: GetRequestHeader('', isNeedAutho) };
  if (responseType) config.responseType = responseType;
  return axios
    .post(url,body, config)
    .then((result) => result)
    .then((response) => {
      if (response.status === 200) {
        if (response.data && response.data.http_code === 200) {
          typeof successCallback === 'function' && successCallback(response.data.data, response.data.message);
        } else if (response.data.http_code === 401) CommonHelper.Logout();
        else typeof failureCallback === 'function' && failureCallback(response.data.message);
      } else if (response.status === 401) CommonHelper.Logout();
      else typeof failureCallback === 'function' && failureCallback(Constants.SomethingWentWrongMsg);
    })
    .catch((error) => {
      if (error.message === 'Network Error') {
      }
      console.log(error);
      typeof failureCallback === 'function' && failureCallback(Constants.SomethingWentWrongMsg);
    });
};

const Get = (props) => {
  let { url, responseType, isNeedAutho = true, successCallback, failureCallback } = props;
  if (DemoHelper.IsDemoAccount()) {
    var resp = DemoHelper.GetDemoJsonFileName(url);
    if (resp.json_file_name != 'no preloaded data') {
      props.url = `${demoModuleUrl.getDemoJson}`;
      props.body = resp
      return GetDemoJson(props);
    }
  } else {
    let config = { headers: GetRequestHeader('', isNeedAutho) };
    if (responseType) config.responseType = responseType;
    return axios
      .get(url, config)
      .then((result) => result)
      .then((response) => {
        if (response.status === 200) {
          if (response.data && response.data.http_code === 200) {
            const urlObj = new URL(url);
            const path = urlObj.pathname;
            if (path == '/api/profile-master-services/private/initial-load') {
              if (response.data.data.is_demo_account ) {
                var demojsonResp = DemoHelper.GetDemoJsonFileName(url);
                if (demojsonResp.json_file_name != 'no preloaded data') {
                  props.url = `${demoModuleUrl.getDemoJson}`;
                  props.body = demojsonResp
                  return GetDemoJson(props);
                }
              } else {
                typeof successCallback === 'function' && successCallback(response.data.data, response.data.message);
              }
            } else {
              typeof successCallback === 'function' && successCallback(response.data.data, response.data.message);
            }
          } else if (response.data.http_code === 401) CommonHelper.Logout();
          else typeof failureCallback === 'function' && failureCallback(response.data.message);
        } else if (response.status === 401) CommonHelper.Logout();
        else typeof failureCallback === 'function' && failureCallback(Constants.SomethingWentWrongMsg);
      })
      .catch((error) => {
        if (error.message === 'Network Error') {
        }
        console.log(error);
        typeof failureCallback === 'function' && failureCallback(Constants.SomethingWentWrongMsg);
      });
  }
};

const Post = (props) => {
  let {
    url,
    body,
    successCallback,
    failureCallback,
    contentType,
    responseType,
    isNeedAutho = true,
    isFileUpload = false,
    progressCallback,
  } = props;
  if (DemoHelper.IsDemoAccount()) {
    var demoResp = DemoHelper.GetDemoJsonFileName(url,body);
    if (demoResp.json_file_name != 'no preloaded data') {
      props.url = `${demoModuleUrl.getDemoJson}`;
      props.body = demoResp
      return GetDemoJson(props);
    }
  } else {
    let config = isFileUpload
      ? { headers: GetRequestHeader(contentType, isNeedAutho) }
      : {
          headers: GetRequestHeader(contentType, isNeedAutho),
          onUploadProgress: (data) => {
            typeof progressCallback === 'function' && progressCallback(Math.round((100 * data.loaded) / data.total));
          },
        };
    if (responseType) config.responseType = responseType;
    return axios
      .post(url, body, config)
      .then((result) => result)
      .then((response) => {
        if (response.status === 200) {
          if (response.data && response.data.http_code === 200) {
            if (typeof successCallback === 'function') {
              successCallback(response.data.data, response.data.message);
            }
          } else if (response.data.http_code === 401) {
            CommonHelper.Logout();
          } else if (typeof failureCallback === 'function') {
            failureCallback(response.data.message);
          }
        } else if (response.status === 401) {
          CommonHelper.Logout();
        } else if (typeof failureCallback === 'function') {
          failureCallback(Constants.SomethingWentWrongMsg);
        }
      })
      .catch((error) => {
        if (error.message === 'Network Error') {
        }
        console.log(error);
        if (typeof failureCallback === 'function') {
          failureCallback(Constants.ErrorReachingServer);
        }
      });
  }
};
const UploadMediaFiles = (props) => {
  let { formData, setFileUploadprogress, successCallback, failureCallback } = props;
  setFileUploadprogress(1);
  return axios
    .post(imageModeuleUrl.uploadMedia, formData, {
      headers: GetRequestHeader('form', true),
      onUploadProgress: (data) => {
        let loadedProgress = Math.round((100 * data.loaded) / data.total);
        setFileUploadprogress(loadedProgress > 90 ? 90 : loadedProgress);
      },
    })
    .then((result) => result)
    .then((response) => {
      setFileUploadprogress(100);
      if (response.status === 200 && response.data.http_code === 200) {
        let mediaData = response.data && response.data?.data.media_data;
        if (mediaData && mediaData.length > 0) {
          let uploadedFiles = mediaData.map((uploadedData) => {
            return {
              media_url: uploadedData.media_url,
              thumbnail_urls: uploadedData.thumbnail_urls,
              aspect_ratio: uploadedData.aspect_ratio,
              video_length: uploadedData.video_length,
              media_type: uploadedData.media_type,
            };
          });
          successCallback(uploadedFiles, 'Files Uploaded Successfully');
        }
      } else failureCallback('Something went wrong. Please contact our technical team');
      setFileUploadprogress(0);
    })
    .catch((error) => {
      setFileUploadprogress(0);
      failureCallback(error.toString());
    });
};
const GetImageFromAPI = (url) => {
  let config = {
    headers: {
      Authorization: getToken(),
    },
    responseType: 'arraybuffer',
  };
  return axios
    .get(url, config)
    .then((result) => result)
    .then((response) => {
      if (response.status === 200) {
        const blob = new Blob([response.data], { type: response.headers['content-type'] });
        return URL.createObjectURL(blob);
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

const HttpServices = {
  Post,
  Get,
  GetRequestHeader,
  GetImageFromAPI,
  UploadMediaFiles,
};

export default HttpServices;
