import { useEffect } from "react";
import { Config } from "src/config";
import HttpServices from "src/services/httpService";
import { integrateModuleURL } from "src/services/urlService";
import PinterestAuthorizationModal from "../pinterestIntegration/pinterestAuthorizationModal";
import { useState } from "react";
import { useToaster } from "src/utils/toaster/toasterContext";
import { CommonHelper } from "src/utils/commonHelper";
import CustomDomainRedirectIframe from '../customDomainIframe';

const PinterestReAuthorizate = ({ AccountId, handleClose, getConnectedNetworks }) => {
  const [isAuthorizationModalOpen, setAuthorizationModal] = useState(true);
  const { showToast } = useToaster();
  const isCustomDomain = CommonHelper.CustomDomain();

  let isProcessing = false;
  let intervalId = null;

  useEffect(() => {
    intervalId = setInterval(() => {
      checkAccessTokenReceivedFromPinterest();
    }, Config.Common.socialAccountsIntegrationInterval);
    return () => {
      clearInterval(intervalId);
    };
  }, [])

  const getPinterestOauthToken = () => {
    window.open(
      `${Config.Pinterest.authorizeUrl}?client_id=${Config.Pinterest.clientId}&scope=${Config.Pinterest.scope}&redirect_uri=${Config.Pinterest.redirectUri}&response_type=code`
    );
  }

  const checkAccessTokenReceivedFromPinterest = () => {
      if (isCustomDomain) {
        const handleMessage = (event) => {
          const { type, code } = event.data;
          if (type === "pinterest_code" && code && !isProcessing) {
            isProcessing = true
            window.removeEventListener("message", handleMessage, false); // Remove event listener
            clearInterval(intervalId); // Stop polling
            sendAccessTokenToServer(code); // Process the code
          }
        };

        window.addEventListener("message", handleMessage, false);
      } else {
        var code = localStorage.getItem("pinterest_code");
        var error = localStorage.getItem("pinterest_error");
        if (code) {
          localStorage.removeItem("pinterest_code");
          clearInterval(intervalId);
          sendAccessTokenToServer(code);
        }
        else if (error) {
          localStorage.removeItem("pinterest_error");
          clearInterval(intervalId);
          showToast(error, 'e');
        }
      }
    };
    const pinterestSuccessCallback = (data, message) => {
      showToast(message, 's');
      getConnectedNetworks();
      handleClose();
    }
    const pinterestFailureCallback = (message) => {
      showToast(message, 'e');
      handleClose();
    }
    const sendAccessTokenToServer = (code) => {
      let pinterestForm = {
        code,
        account_id: AccountId
      };
      let postProps = {
        url: integrateModuleURL.reAuthenticatePinterest,
        body: pinterestForm,
        successCallback: pinterestSuccessCallback,
        failureCallback: pinterestFailureCallback
      }
      HttpServices.Post(postProps);
    };

    return (
      <>{isAuthorizationModalOpen &&
        <PinterestAuthorizationModal
          handleConnectPinterest={getPinterestOauthToken}
          handleClose={handleClose}
        />}{isCustomDomain && <CustomDomainRedirectIframe serviceName="pinterest" />}</>
    );
  };
  export default PinterestReAuthorizate;