import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Stack,
  TextField,
  Typography,
  MenuItem,
  Select,
  InputAdornment,
} from '@mui/material';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { reportModuleURL } from 'src/services/urlService';
import HttpServices from 'src/services/httpService';
import { CommonHelper } from 'src/utils/commonHelper';
import { StyledComponent } from 'src/components/styled-components/styledComponent';
import { useToaster } from 'src/utils/toaster/toasterContext';

const SendAsEmailComponent = ({
  serviceName,
  startDate,
  endDate,
  viewBy,
  connectedProfileId,
  reportId,
  subReportId = 0,
  currReportData
}) => {

  const sideBarWidth = 500;
  const { showToast } = useToaster();
  let currentBrandId = CommonHelper.GetCurrentBrandId();
  const brandDetails = CommonHelper.GetBrandInfoFromLocalStorage("brand_details");
  const currentBrandDetails = brandDetails?.find((a) => a.brand_id === currentBrandId);
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState(`Check out the  ${serviceName} Account ${currReportData.report_display_name ? currReportData.report_display_name :currReportData.card_name} for ${currentBrandDetails.brand_name}`);
  const [message, setMessage] = useState(`Just wanted to let you know that I have shared ${serviceName} ${currReportData.report_display_name || currReportData.card_name} report for ${currentBrandDetails.brand_name} with you. Go ahead and take a look at the attachment below.`);
  const [format, setFormat] = useState('PDF');
  const [passwordProtect, setPasswordProtect] = useState(false);
  const [height, setHeight] = useState(window.innerHeight - 150);
  const [password, setPassword] = useState('');
  const [sendCopy, setSendCopy] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const handleSend = () => {
    // Reset error messages
    setEmailError('');
    setPasswordError('');

    if (!email) {
      setEmailError('Email address is required');
      showToast('Email address is required', 'e');
      return;
    }

    // Validate email format
    const emailList = email.split(',').map((e) => e.trim());
    const isValidEmail = emailList.every((emailItem) => /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(emailItem));

    if (!isValidEmail) {
      setEmailError('Please enter valid email addresses');
      showToast('Please enter valid email addresses', 'e');
      return;
    }

    if (passwordProtect && password === '') {
      setPasswordError('Please enter the password');
      showToast('Please enter the password', 'e');
      return;
    }

    setLoading(true);
    const formBody = {
      from: startDate,
      to: endDate,
      view_by: viewBy,
      type: format,
      report_id: reportId,
      sub_report_id: subReportId,
      service_name: serviceName,
      connected_profile_id: connectedProfileId,
      brand_id:currentBrandId
    };

    const emailbody = {
      recipient_email_addresses: emailList,
      subject: subject,
      message: message,
      report_format: format,
      is_password_protected: passwordProtect,
      password: passwordProtect ? password : '',
      send_me_a_copy: sendCopy
    };

    const getProps = {
      url: reportModuleURL.sendAsEmailReport,
      body: {
        export_report: formBody,
        email_data: emailbody
      },
      successCallback,
      failureCallback,
    };
    HttpServices.Post(getProps);
  };

  const successCallback = (data) => {
    showToast('Email sent successfully!', 's');
    setLoading(false);
  };

  const failureCallback = (message) => {
    showToast(message, 'e');
    setLoading(false);
  };

  return (
    <StyledComponent.StyledStack sx={{ width: sideBarWidth, p: 3 }}>
      <Typography variant="h6" gutterBottom>Email Report</Typography>
      <Stack spacing={2}>
        <TextField
          label="Recipient's Email Address"
          placeholder="Type email addresses separated by comma"
          fullWidth
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={!!emailError}
          helperText={emailError}
        />

        <TextField
          label="Subject"
          multiline
          rows={2}
          fullWidth
          required
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
        />

        <TextField
          label="Message"
          multiline
          rows={5}
          fullWidth
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />

        <Box>
          <Typography variant="body1" gutterBottom>Report Format</Typography>
          <Select
            value={format}
            onChange={(e) => setFormat(e.target.value)}
            fullWidth
          >
            <MenuItem value="PDF">PDF</MenuItem>
            {/* <MenuItem value="PNG" disabled>PNG</MenuItem> */}
          </Select>
        </Box>

        <FormControlLabel
          control={
            <Checkbox
              checked={passwordProtect}
              onChange={(e) => setPasswordProtect(e.target.checked)}
            />
          }
          label="Password Protect"
        />

        {passwordProtect && (
          <TextField
            label="Set a password"
            placeholder="Password should contain at least 6 characters."
            type="password"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={!!passwordError}
            helperText={passwordError}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography variant="body2">*</Typography>
                </InputAdornment>
              ),
            }}
          />
        )}

        {/* <FormControlLabel
          control={
            <Checkbox
              checked={sendCopy}
              onChange={(e) => setSendCopy(e.target.checked)}
            />
          }
          label="Send me a copy"
        /> */}

        <Divider />

        <Stack direction="row" spacing={2}>
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={handleSend}
            loading={isLoading}
          >
            Send
          </LoadingButton>
          {/* <Button color="inherit" onClick={() => console.log('Cancel clicked')}>Cancel</Button> */}
        </Stack>
      </Stack>
    </StyledComponent.StyledStack>
  );
};

export default SendAsEmailComponent;
