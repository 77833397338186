import React from 'react';
import { Card, CardContent, Typography, Grid, Box } from '@mui/material';
import { CommonHelper } from 'src/utils/commonHelper';
const SummaryCommonCard = ({ title, data, percentage, percentageState, averageData }) => {
  const isPositive = percentageState !== 'down';

  return (
    <Card style={{ width: '100%' }}>
      <CardContent>
        <Grid container spacing={2}>
          {/* Card Title */}
          <Grid item xs={12}>
            <Typography variant="h5" color="textSecondary" gutterBottom>
              {title}
            </Typography>
            <Box display="flex" alignItems="center" sx={{ gap: 1 }}>
              <Typography variant="h2" color="primary" sx={{ marginRight: 1 }}>
                {CommonHelper.formatNumber(data) || 0}
              </Typography>
              {percentage && (
                <Box display="flex" alignItems="center" sx={{ gap: 0.5 }}>
                  {/* Using text symbols for PDF compatibility */}
                  <Typography variant="h5" sx={{ color: isPositive ? 'green' : 'red', fontSize: '24px', lineHeight: '24px' }}>
                    {isPositive ? '▲' : '▼'}
                  </Typography>
                  <Typography variant="h5" sx={{ color: isPositive ? 'green' : 'red' }}>
                    {percentage}%
                  </Typography>
                </Box>
              )}
            </Box>
            {averageData && (
              <Typography variant="body1" color="textSecondary" sx={{ marginTop: 1 }}>
                Avg. per day: {averageData || '<1'}
              </Typography>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default SummaryCommonCard;
