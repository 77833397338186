import { Box, Button, TextField, Typography } from '@mui/material';
import { useState } from 'react';

const PostHashtagGenerator = ({ hashtags, handleUseHashtag }) => {




  const useHashtag = () => {
    handleUseHashtag(hashtags);
  };

  return (
    <Box sx={{ mt: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6">Hashtag Generator</Typography>
        <Button variant="contained" onClick={useHashtag}>
          Use Hashtags
        </Button>
      </Box>
      <TextField
        name="hashtags"
        placeholder="Generated hashtags will appear here"
        fullWidth
        multiline
        rows={4}
        value={hashtags}
        sx={{ mt: 2 }}
      />
    </Box>
  );
};

export default PostHashtagGenerator;
