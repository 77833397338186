import { LoadingButton } from '@mui/lab';
import { Box, Card, Grid, Stack, Typography } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import ConfirmationDialog from 'src/components/ConfirmationDialog';
import { FormProvider, RHFTextField, RHFUploadAvatar } from 'src/components/hook-form';
import LinearProgressBar from 'src/components/progress-bar/progressBar.component';
import HttpServices from 'src/services/httpService';
import { imageModeuleUrl, userModuleURL } from 'src/services/urlService';
import { CommonHelper } from 'src/utils/commonHelper';
import { LocalStorage } from 'src/utils/storage/local-storage';
import { TimeHelper } from 'src/utils/time-helper';
import { useToaster } from 'src/utils/toaster/toasterContext';
import ValidationTool from 'src/utils/validationHelper';
import TimeZoneSelect from 'src/components/time-zone/time-zone.component';

const BrandInfoComponent = () => {
  const isOrgUser = CommonHelper.GetBrandInfoFromLocalStorage('is_org_user');
  const activeBrandId = CommonHelper.GetCurrentBrandId();
  const isUserHasPermission = CommonHelper.CheckForPermissionAvailableForUser();
  const maxFileSizeInBytes = 10485760;
  const supportedFileFormat = ['image/jpeg', 'image/png', 'image/jpg'];
  const timeZoneList = TimeHelper.GetTimeZoneList();

  const { showToast } = useToaster();
  const navigate = useNavigate();

  const [brandName, setBrandName] = useState('');
  const [imageUrl, setBrandImage] = useState('');
  const [brandDescription, setBrandDescription] = useState('');
  const [formSchema, setFormSchema] = useState({});
  const [formValidationErrors, setFormValidationErrors] = useState({});
  const [isSaveChangesLoading, setSaveChangesLoader] = useState(false);
  const [isLeaveBrandLoading, setLeaveBrandLoader] = useState(false);
  const [fileUploadprogress, setFileUploadprogress] = useState(0);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [timeZone, setTimeZone] = useState('UTC');
  const [aiKey, setAIKey] = useState('');
  const [hasAiKey, setHasAIKey] = useState(false);

  useEffect(() => {
    generateFormModelSchema();
    getBrandInfo(activeBrandId);
    // getCurrentTimeZone();
  }, []);

  // const getCurrentTimeZone = () => {
  //   setTimeZone(CommonHelper.GetBrandTimeZone());
  // };
  const getBrandInfo = () => {
    let getProps = {
      url: userModuleURL.fetchBrandInfo,
      successCallback: brandSuccessCallBack,
      failureCallback,
    };
    HttpServices.Get(getProps);
  };
  const brandSuccessCallBack = (data, message) => {
    setBrandName(data.brand_name);
    setTimeZone(data.timezone);
    setBrandDescription(data.description);
    setBrandImage(data.brand_image);
    if (data.has_ai_key) {
      setAIKey('**************');
    }
    setHasAIKey(false);
  };
  const successCallback = (data, message) => {
    showToast(message, 's');
    setSaveChangesLoader(false);
  };
  const failureCallback = (message) => {
    showToast(message, 'e');
    setSaveChangesLoader(false);
    setLeaveBrandLoader(false);
  };
  const handleSubmitForm = () => {
    let userForm = {
      brandName: brandName,
    };
    let { formValidationErrors, isValidForm } = ValidationTool.FormValidation(formSchema, userForm);
    if (isValidForm) {
      setFormValidationErrors({});
      onSubmit();
    } else setFormValidationErrors(formValidationErrors);
  };
  const handleDelete = () => {
    let brandProps = {
      brand_id: activeBrandId,
    };
    setSaveChangesLoader(true);
    let postProps = {
      url: userModuleURL.deleteBrand,
      body: brandProps,
      successCallback: brandDetailsCallback,
      failureCallback,
    };
    HttpServices.Post(postProps);
  };
  const onSubmit = () => {
    setSaveChangesLoader(true);
    const formattedTimeZone = timeZone.split(':')[0].trim();
    let brandProps = {
      brand_Name: brandName,
      brand_image: imageUrl,
      description: brandDescription,
      timezone: formattedTimeZone,
      ai_key: aiKey,
      has_ai_key: hasAiKey,
    };
    let postProps = {
      url: userModuleURL.updateBrand,
      body: brandProps,
      successCallback: brandDetailsCallback,
      failureCallback,
    };
    HttpServices.Post(postProps);
  };
  const brandDetailsCallback = (data, message) => {
    CommonHelper.SetAllBrandDetials();
    setSaveChangesLoader(false);
    showToast(message, 's');
    window.location.reload();
  };
  const handleDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    handleFileUpload(file);
  };
  const fileUploadSuccessCallback = (data, message) => {
    setBrandImage(data.media_data[0].media_url);
  };
  const handleFileUpload = (file) => {
    let isFileInCorrectFormat = supportedFileFormat.some((a) => a.includes(file.type));
    if (isFileInCorrectFormat) {
      if (file.size < maxFileSizeInBytes) {
        setFileUploadprogress(1);
        let formData = new FormData();
        formData.append('fileupload', file);
        axios
          .post(imageModeuleUrl.uploadMedia, formData, {
            headers: HttpServices.GetRequestHeader('form', true),
            onUploadProgress: (data) => {
              let loadedProgress = Math.round((100 * data.loaded) / data.total);
              setFileUploadprogress(loadedProgress > 90 ? 90 : loadedProgress);
            },
          })
          .then((result) => result)
          .then((response) => {
            setFileUploadprogress(100);
            if (response.status === 200 && response.data.http_code === 200)
              fileUploadSuccessCallback(response.data.data, 'File Uploaded Successfully');
            else showToast('Something went wrong. Please contact our technical team', 'e');
            setFileUploadprogress(0);
          })
          .catch((error) => {
            setFileUploadprogress(0);
            showToast(error.toString(), 'e');
          });
      } else showToast('Maximum file size for image is 10MB. Please select image below 10 MB', 'e');
    } else {
      showToast('We only support JPEG, and PNG file formats. The selected file are not in the supported format', 'e');
    }
  };
  const handleInputChange = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    switch (name) {
      case 'brandName':
        setBrandName(value);
        break;
      case 'brandDescription':
        setBrandDescription(value);
        break;
      case 'timezone':
        setTimeZone(value);
        break;
      case 'aiKey':
        setHasAIKey(true);
        if (value?.includes('*')) {
          setAIKey("");
        } else {
          setAIKey(value);
        }
        break;
      default:
        break;
    }
  };
  const handleAccountStatus = (member) => {
    let bodyProps = {
      brand_id: activeBrandId,
    };
    let postProps = {
      url: member.is_enabled ? userModuleURL.disableBrand : userModuleURL.enableBrand,
      body: bodyProps,
      successCallback,
      failureCallback,
    };
    HttpServices.Post(postProps);
  };
  const leaveCallback = (data, message) => {
    window.location.href = window.location.origin;
  };
  const handleLeave = () => {
    setConfirmOpen(true);
  };
  const handleLeaveBrand = () => {
    setLeaveBrandLoader(true);
    let postProps = {
      url: userModuleURL.leaveBrand,
      successCallback: leaveCallback,
      failureCallback,
    };
    HttpServices.Post(postProps);
  };
  const generateFormModelSchema = () => {
    let fields = [ValidationTool.GenerateSchema('brandName', 'Brand Name', 'TEXT', true)];
    const schema = ValidationTool.GenerateSchemaModel(fields);
    setFormSchema(schema);
  };
  const timezoneCallback = (data, message) => {
    message && showToast(message, 's');
  };
  const handleChangeTimeZone = (event) => {
    let value = event.target.value;
    setTimeZone(value);
    LocalStorage.setItem('timezone', JSON.stringify(value));
    let bodyProps = {
      timezone: value,
    };
    let postProps = {
      url: userModuleURL.changeTimeZone,
      body: bodyProps,
      successCallback: timezoneCallback,
      failureCallback,
    };
    HttpServices.Post(postProps);
  };
  const handleDeleteBrandImage = () => {
    setBrandImage('');
  };
  const defaultValues = {
    brandName: brandName || '',
    imageUrl: imageUrl || '',
    brandDescription: brandDescription || '',
    timeZone: timeZone || '',
  };
  const methods = useForm({
    defaultValues,
  });
  const { handleSubmit } = methods;
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(handleSubmitForm)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Box display="flex" flexDirection="column" height="100%">
            <Card sx={{ pt: 10, pb: 5, px: 3, textAlign: 'center', flexGrow: 1 }}>
              <Box position="relative" display="inline-block">
                <RHFUploadAvatar
                  name="imageUrl"
                  accept="image/*"
                  maxSize={5242880}
                  onDrop={handleDrop}
                  handleDeleteProfiles={handleDeleteBrandImage}
                  url={imageUrl}
                  helperText={
                    <Typography
                      variant="caption"
                      sx={{
                        mt: 2,
                        mx: 'auto',
                        display: 'block',
                        textAlign: 'center',
                        color: 'text.secondary',
                      }}
                    >
                      Allowed *.jpeg, *.jpg, *.png, *.gif
                      <br /> max size of 5Mb
                    </Typography>
                  }
                />
              </Box>
              <Stack alignItems="center" justifyContent="center">
                {!isOrgUser && (
                  <LoadingButton variant="contained" loading={isLeaveBrandLoading} onClick={handleLeave}>
                    Leave Brand
                  </LoadingButton>
                )}
                <ConfirmationDialog
                  open={confirmOpen}
                  onClose={() => setConfirmOpen(false)}
                  onConfirm={handleLeaveBrand}
                  title="Confirm Leave Brand"
                  message="Are you sure you want to Leave this brand?"
                />
              </Stack>
            </Card>
            {fileUploadprogress > 0 && <LinearProgressBar value={fileUploadprogress} />}
          </Box>
        </Grid>

        <Grid item xs={12} md={8}>
          <Box display="flex" flexDirection="column">
            <Card sx={{ p: 3, flexGrow: 1 }}>
              <Box
                sx={{
                  display: 'grid',
                  rowGap: 3,
                  columnGap: 2,
                  gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                }}
              >
                <RHFTextField
                  name="brandName"
                  label="Brand Name"
                  value={brandName}
                  onChange={handleInputChange}
                  error={formValidationErrors?.brandName}
                  helperText={formValidationErrors?.brandName}
                />
                <RHFTextField
                  name="aiKey"
                  label="AI Key"
                  value={aiKey}
                  onChange={handleInputChange}
                  helperText={
                    <a
                      href="https://www.youtube.com/watch?v=ZEQ102Wq1Lk"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        textDecoration: 'none',
                        marginLeft: '-10px',
                        cursor: 'pointer'
                      }}
                    >
                      Click here to learn how to get the AI Key?(Enabled Chaptgpt-4.0-latest)
                    </a>
                  }
                />
                <Stack>
                  <TimeZoneSelect name="timezone" value={timeZone} onChange={handleInputChange} />
                </Stack>
              </Box>
              <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
                <RHFTextField
                  name="brandDescription"
                  multiline
                  rows={5}
                  label="Brand Description"
                  value={brandDescription}
                  onChange={handleInputChange}
                />
              </Stack>
              {isUserHasPermission && (
                <Stack alignItems="flex-end">
                  <Stack spacing={3} direction="row" sx={{ mt: 3 }}>
                    <LoadingButton type="submit" variant="contained" loading={isSaveChangesLoading}>
                      Save Changes
                    </LoadingButton>
                  </Stack>
                </Stack>
              )}
            </Card>
          </Box>
        </Grid>
      </Grid>
    </FormProvider>
  );
};
export default BrandInfoComponent;
